import FeatureGates from '@atlaskit/feature-gate-js-client';

// These are the experiment keys for our experiments created on Statsig.
export const AI_EXPERIMENTS = {
	// Replaces "platform.editor.ai-persistent-placeholder"
	'editor_ai_-_persistent_placeholder_experiment': ['control', 'persistent-placeholder'],
	'editor_ai_-_gpt4_experiment': ['control', 'einstein'],
	'editor_ai_-_proactive_ai_model_variations': ['control', 'skip_grammar'],
	'editor_ai_-_proactive_ai_spelling_and_grammar': ['control', 'default-off', 'default-on'],
	'platform_editor_ai-prompts-placeholder': ['control', 'test'],
} as const;

type AIExperiments = typeof AI_EXPERIMENTS;

export type ExperimentKey = keyof AIExperiments;

export type ExperimentCohort = AIExperiments[ExperimentKey][number];

type ExperimentKeyCohort<T extends ExperimentKey> = AIExperiments[T][number];

/**
 * Reads the cohort (experiment bucket) which the current user is part of
 * for the given `experimentKey`.
 *
 * @param experimentKey string to ideitfy the experiment.
 * @returns T|'not-enrolled' The cohort the user is part of, or 'not-enrolled'
 * if the user landed into the non-targeted segment (if rollout is < 100% or experiment paused)
 */
export const getExperimentCohort = <T extends ExperimentKey>(
	experimentKey: T,
): ExperimentKeyCohort<T> | 'not-enrolled' => {
	const getExperimentValue = FeatureGates.getExperimentValue;

	return getExperimentValue<ExperimentCohort | 'not-enrolled'>(
		experimentKey,
		'cohort',
		'not-enrolled',
	);
};

export const getMockedGetExperimentValue =
	<T extends ExperimentKey>(key: T, cohort: ExperimentCohort) =>
	(experimentKey: string, parameter: string, defaultValue: any = null) => {
		if (experimentKey === key) {
			if (parameter === 'cohort') {
				return cohort;
			} else if (parameter === 'isEnabled') {
				return true;
			}
		}

		return defaultValue;
	};
