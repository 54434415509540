import type { ADNode } from "@atlaskit/editor-common/validator";
import type { MacroAttributes } from "@atlaskit/editor-common/provider-factory";
import window from "window-or-global";

import type {
  ExtensionAnalyticsInfo,
  LegacyPlaceholderMacro,
  MacroBrowserSettings,
  MacroConfig,
  MacroDefinition,
  MacroMetadata,
} from "@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types";
import {
  applyBlocklist,
  convertBody,
  fetchMacroAttributes,
  formatAsLegacyMacro,
  getMacroMetaData,
} from "@confluence/fabric-extension-lib/entry-points/editor-extensions";
import {
  allowedMacrosForInsertMenu,
  EMPTY_ADF_CONTENT,
  MODERENIZED_MACRO_KEYS,
} from "@confluence/fabric-extension-lib/entry-points/extensionConstants";
import {
  isBodiedExtension
} from "@confluence/fabric-extension-lib/entry-points/isBodiedExtension";
import { getLogger } from "@confluence/logger";
import { isRequiredModuleLoaded } from "@confluence/wrm";

import { loadMacroBrowserResources } from "../extensions-common/macro-loaders";
import { MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH } from "../../constants/editor-constants";

// An empty adf node to fallback to if no selected macro is provided
const fallbackADNode: ADNode = {
  type: "extension",
  content: EMPTY_ADF_CONTENT,
};

export const defaultContentByLabelParams = {
  showLabels: {
    value: "true",
  },
  max: {
    value: "15",
  },
  sort: {
    value: null,
  },
  showSpace: {
    value: "true",
  },
  reverse: {
    value: "false",
  },
  excerptType: {
    value: "none",
  },
};

export function getFeaturedMacros(): Promise<MacroMetadata[]> {
  return getMacroMetaData().then((macros) =>
    macros.filter((item) => allowedMacrosForInsertMenu.includes(item.macroName))
  );
}

export function constructEmbeds(macroDefinition): ADNode {
  return {
    type: "embedCard",
    attrs: {
      url: macroDefinition.params && macroDefinition.params.url,
      layout: "wide",
    },
  };
}

const fireAnchorAnalyticsEvent = (
  createAnalyticsEvent,
  action,
  contentId,
  nameStatus
) => {
  createAnalyticsEvent({
    type: "sendUIEvent",
    data: {
      action,
      actionSubject: `${action}Anchor`,
      source: "extensions-editor",
      attributes: {
        contentId,
        nameStatus,
      },
    },
  }).fire();
};

const fireJIMAnalyticsEvent = (
  contentId,
  prevNodeType,
  newNodeType,
  createAnalyticsEvent
) => {
  const tableToIssueCount =
    prevNodeType === "extension" && newNodeType === "inlineExtension";
  const issueCountToTable =
    prevNodeType === "inlineExtension" && newNodeType === "extension";
  if (tableToIssueCount || issueCountToTable) {
    createAnalyticsEvent({
      type: "sendUIEvent",
      data: {
        action: "clicked",
        actionSubject: "button",
        actionSubjectId: "changedJiraIssueMacroExtensionView",
        source: "JiraIssueMacro",
        objectId: contentId,
        attributes: {
          tableToIssueCount,
          issueCountToTable,
        },
      },
    }).fire();
  }
};

const fireContentByLabelAnalyticsEvent = (
  contentId,
  parameters,
  createAnalyticsEvent
) => {
  const getUGCFreeParameters = () => {
    const { cql, title, ...rest } = parameters;
    return { ...defaultContentByLabelParams, ...rest };
  };
  if (parameters) {
    createAnalyticsEvent({
      type: "sendUIEvent",
      data: {
        action: "closed",
        actionSubject: "modal",
        actionSubjectId: "macroBrowser",
        source: "ContentByLabel",
        objectId: contentId,
        attributes: {
          extensionKey: "contentbylabel",
          parameters: {
            ...getUGCFreeParameters(),
          },
        },
      },
    }).fire();
  }
};

const fireErrorAnalytics = (
  contentId,
  actionSubject,
  createAnalyticsEvent,
  error,
  extensionKey?
) => {
  createAnalyticsEvent({
    type: "sendOperationalEvent",
    data: {
      action: "error",
      actionSubject,
      source: "editor-extensions",
      objectId: contentId,
      attributes: {
        error: error?.message ?? error,
        stack: error?.stack,
        extensionKey,
      },
    },
  }).fire();
};

export async function transformMacro(
  macroDefinition,
  isEditingMacro,
  extensionAnalyticsInfo?: ExtensionAnalyticsInfo
) {
  if (macroDefinition.name === "anchor") {
    const anchorNameProvidedByUser = macroDefinition.params[""];

    // Strip spaces off the beginning and end of the name
    let anchorNameWithoutSpaces = anchorNameProvidedByUser.replace(/^\s+/g, "");
    anchorNameWithoutSpaces = anchorNameWithoutSpaces.replace(/\s+$/g, "");

    // Changing any remaining spaces to '-' to match header logic
    anchorNameWithoutSpaces = anchorNameWithoutSpaces.replace(/\s+/g, "-");

    // Stripping out any single or double quotes for security (VULN-539679)
    anchorNameWithoutSpaces = anchorNameWithoutSpaces.replace(/[\"\']+/g, "");

    if (extensionAnalyticsInfo && extensionAnalyticsInfo.createAnalyticsEvent) {
      let nameStatus;
      if (anchorNameWithoutSpaces === "") {
        nameStatus = "missing";
      } else {
        anchorNameWithoutSpaces = anchorNameWithoutSpaces.substring(
          0,
          MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH
        );

        try {
          decodeURIComponent(anchorNameWithoutSpaces);
          nameStatus = "valid";
        } catch {
          nameStatus = "invalid";
        }
      }

      fireAnchorAnalyticsEvent(
        extensionAnalyticsInfo.createAnalyticsEvent,
        extensionAnalyticsInfo.action,
        extensionAnalyticsInfo?.contentId,
        nameStatus
      );
    }

    return {
      type: "inlineExtension",
      attrs: {
        extensionType: "com.atlassian.confluence.macro.core",
        extensionKey: "anchor",
        parameters: {
          macroParams: {
            "": {
              value: anchorNameWithoutSpaces,
            },
          },
          macroMetadata: {
            title: "Anchor",
          },
        },
      },
    };
  } else if (macroDefinition.name === "jiraroadmap") {
    /** jiraroadmap is always in confluence/next/packages/editor-features/src/utils/smartCardOptions.ts */
    if (!isEditingMacro) {
      return constructEmbeds(macroDefinition);
    }
    /*
      in order to render a single issue as a smart link the following conditions must be true or we default to inlineExtension
        - issue must be from a cloud server (params.key is defined and domain must be of a cloud instance)
        - required module from monolith (applinks-bridge-for-spa) must be loaded
        - the browser origin and issue link must be in the same environment (e.g. prod and prod, staging and staging) - only an issue for devs
    */
  } else if (macroDefinition.name === "jira" && !!macroDefinition.params.key) {
    if (isRequiredModuleLoaded("confluence/jim/jira/applinks-bridge-for-spa")) {
      try {
        const ApplinksBridge = window.require(
          "confluence/jim/jira/applinks-bridge-for-spa"
        );

        const issueUrl = await ApplinksBridge.getUrlForKey(
          macroDefinition.params.key,
          macroDefinition.params.serverId
        );
        if (
          (await ApplinksBridge.isCloudAppLinkFromServerId(
            macroDefinition.params.serverId
          )) &&
          issueUrl
        ) {
          const isIssueSameEnv =
            (issueUrl.indexOf("atlassian.net") !== -1) ===
            (window.location.origin.indexOf("atlassian.net") !== -1);

          // allow smart link insertion from localhost
          if (isIssueSameEnv || process.env.NODE_ENV !== "production") {
            return {
              type: "inlineCard",
              attrs: {
                url: issueUrl,
              },
            };
          }
        }
      } catch (e) {}
    }
  }

  return null;
}

export function prepareInsertMacro(
  macro: MacroMetadata,
  config: MacroConfig,
  extensionAnalyticsInfo?: ExtensionAnalyticsInfo
): Promise<MacroAttributes> {
  const name = macro.macroName;
  // CFE-2344: despite not having a required parameter, the children macro should open the macro browser
  // so that the user can select a parent page. Without this, on a brand new page, the children display
  // macro renders a loading state, then completely disappears and looks broken.
  // CONFDEV-62041: tasks report macro also doesn't have a required parameter, but without any parameters
  // it tries to search the entirety of Confluence for tasks and usually times out, so let's ask for parameters anyway.
  if (
    macro.anyParameterRequired ||
    name === "children" ||
    name === "tasks-report-macro"
  ) {
    return openMacroBrowserForInitialConfiguration(
      macro,
      config,
      undefined,
      extensionAnalyticsInfo
    );
  }

  return fetchMacroAttributes({
    macroDefinition: {
      name,
      body: "",
      params: {},
    },
    contentId: config.contentId,
  });
}

export function getMacroDefinition(
  selectedMacroDefinition: MacroDefinition
): MacroDefinition {
  const { name, body, params, defaultParameterValue, schemaVersion } =
    selectedMacroDefinition;

  return {
    name: name || "",
    body: body || "",
    params,
    defaultParameterValue: defaultParameterValue || "",
    schemaVersion,
  };
}

export async function prepareNodeFromMacroBrowser(
  macroDefinition: MacroDefinition,
  contentId: string | undefined,
  selectedMacro: ADNode | MacroMetadata | null
): Promise<MacroAttributes> {
  const newMacroNode = await fetchMacroAttributes({
    macroDefinition: macroDefinition as LegacyPlaceholderMacro,
    contentId,
    selectedMacro: null,
  });

  if (isBodiedExtension(newMacroNode.type)) {
    // Updating the content of the macro if it was changed with saveMacro
    // If saveMacro does not include the body, macroDefinition.body will
    // be substituted with some function
    if (typeof macroDefinition.body === "string") {
      let convertedBody;
      try {
        convertedBody =
          (await convertBody(
            "storage",
            "atlas_doc_format",
            macroDefinition.body,
            contentId
          )) || "";
        newMacroNode.content = JSON.parse(convertedBody).content;
      } catch (error) {
        const logger = getLogger("getMacroBrowserSettings.onComplete");
        logger.error`Can't convert provided body from storage format to ADF: ${convertedBody}`;

        // if provided body can't be converted to ADF return the previous content
        if (selectedMacro?.content) {
          newMacroNode.content = selectedMacro?.content;
        }
      }
    } else if (selectedMacro?.content) {
      newMacroNode.content = selectedMacro?.content;
    }
  }

  return newMacroNode;
}

export async function getMacroBrowserSettings(
  macroBrowser: any,
  config: MacroConfig,
  selectedMacro: ADNode | MacroMetadata | null,
  macroBrowserResolve: Function,
  macroBrowserReject: Function,
  extensionAnalyticsInfo?: ExtensionAnalyticsInfo,
  onCompleteCallback?: (macro: string | undefined) => void,
  updateMacro?: (macro) => void
): Promise<MacroBrowserSettings> {
  const blocklistRestore = await applyBlocklist(macroBrowser);

  let isInserting;
  let insertUpdatedMacroFailureCount = 0;

  return {
    autoClose: false,
    onComplete: async (rowMacroDefinition: MacroDefinition) => {
      isInserting = true;

      macroBrowser.UI.showBrowserSpinner(true);

      const macroDefinition = getMacroDefinition(rowMacroDefinition);

      try {
        let newMacroNode = await transformMacro(
          macroDefinition,
          selectedMacro?.attrs?.extensionKey === "jiraroadmap",
          extensionAnalyticsInfo
        );
        // If no transformation happen process as general macro
        if (!newMacroNode) {
          newMacroNode = await prepareNodeFromMacroBrowser(
            macroDefinition,
            config.contentId,
            selectedMacro
          );

          if (
            selectedMacro?.attrs?.extensionKey === "jira" &&
            extensionAnalyticsInfo &&
            extensionAnalyticsInfo.createAnalyticsEvent
          ) {
            fireJIMAnalyticsEvent(
              config.contentId,
              selectedMacro?.type,
              newMacroNode?.type,
              extensionAnalyticsInfo?.createAnalyticsEvent
            );
          }

          if (
            macroDefinition?.name === "contentbylabel" &&
            extensionAnalyticsInfo &&
            extensionAnalyticsInfo.createAnalyticsEvent
          ) {
            fireContentByLabelAnalyticsEvent(
              config.contentId,
              newMacroNode?.attrs?.parameters?.macroParams,
              extensionAnalyticsInfo?.createAnalyticsEvent
            );
          }
        }

        macroBrowserResolve(newMacroNode);
        macroBrowser.close();
        blocklistRestore();
        onCompleteCallback?.(rowMacroDefinition?.name);
      } catch (err) {
        if (MODERENIZED_MACRO_KEYS.includes(selectedMacro?.attrs?.extensionKey)) {
          fireErrorAnalytics(
            config.contentId,
            "onComplete",
            extensionAnalyticsInfo?.createAnalyticsEvent,
            err,
            selectedMacro?.attrs?.extensionKey
          );
        }
        macroBrowserReject(err);
      }
    },
    onCancel: () => {
      blocklistRestore();
      // Macro browser can call cancel on insert, so we need to keep state
      // and only reject when someone isn't trying to insert
      if (!isInserting) {
        macroBrowserReject({
          reason: "cancel",
        });
      }
    },
    insertUpdatedMacro: async (rowMacroDefinition: MacroDefinition) => {
      const macroDefinition = getMacroDefinition(rowMacroDefinition);
      try {
        const newMacroNode = await prepareNodeFromMacroBrowser(
          macroDefinition,
          config.contentId,
          selectedMacro
        );
        updateMacro?.(newMacroNode);
        insertUpdatedMacroFailureCount = 0;
      } catch (error) {
        insertUpdatedMacroFailureCount++;
        if (insertUpdatedMacroFailureCount > 5) {
          insertUpdatedMacroFailureCount = 0;
          fireErrorAnalytics(
            config.contentId,
            "insertUpdatedMacro",
            extensionAnalyticsInfo?.createAnalyticsEvent,
            error,
            selectedMacro?.attrs?.extensionKey
          );
        }
      }
    },
  };
}

/**
 * opens the legacy macro browser for editing an existing macro
 * This method accepts an ADNode, which is what the Fabric editor gives us after a macro has already been inserted
 *
 * @param selectedMacro the macro to configure
 * @param config the configuration for the macro browser
 * @param macroFilter a filter to filter out undesired macros
 * @returns {Promise} resolves with the macro definition
 */
export function openMacroBrowserToEditExistingMacro(
  selectedMacro: ADNode | null,
  config: MacroConfig,
  macroFilter?: (macro: MacroMetadata) => boolean,
  extensionAnalyticsInfo?: ExtensionAnalyticsInfo,
  updateMacro?: any
): Promise<MacroAttributes> {
  return new Promise(async (resolve, reject) => {
    const selectedMacroFormattedAsLegacyMacro = await formatAsLegacyMacro(
      selectedMacro || fallbackADNode
    );
    const macroBrowser: any = await loadMacroBrowserResources();

    // filter based on dynamic data such as feature flags
    if (macroFilter) {
      macroBrowser.metadataList = macroBrowser.metadataList.filter(macroFilter);
    }

    if (extensionAnalyticsInfo) {
      extensionAnalyticsInfo.action = "update";
    }

    const macroBrowserSettings: MacroBrowserSettings =
      await getMacroBrowserSettings(
        macroBrowser,
        config,
        selectedMacro,
        resolve,
        reject,
        extensionAnalyticsInfo,
        undefined,
        updateMacro
      );

    try {
      macroBrowser.open({
        ...macroBrowserSettings,
        selectedMacro: {
          ...selectedMacroFormattedAsLegacyMacro,
          productContext: {
            // product context is used by connect macros, when they call /wiki/plugins/servlet/ac/{app-key}/{macro-key}
            // this endpoint enriches the response's structuredContext field, which is used by AP.context.getContext
            // ideally would be good to check that this is a connect macro, however, it seems
            // that there's no information available to check that
            "content.id": config.contentId,
            "enrich-content-context-parameters": true,
          },
        },
      });
    } catch (err) {
      fireErrorAnalytics(
        config.contentId,
        "openMacroBrowserToEditExistingMacro",
        extensionAnalyticsInfo?.createAnalyticsEvent,
        err,
        selectedMacro?.attrs?.extensionKey
      );
      reject(err);
    }
  });
}

/**
 * opens the legacy macro browser for configuring a macro for the first time
 * This method accepts a macroMetadata object which a blob we configure ourselves
 *
 * @param selectedMacro the macro to configure
 * @param config the configuration for the macro browser
 * @param macroFilter a filter to filter out undesired macros
 * @returns {Promise} resolves with the macro definition
 */
export function openMacroBrowserForInitialConfiguration(
  selectedMacro: MacroMetadata,
  config: MacroConfig,
  macroFilter?: (macro: MacroMetadata) => boolean,
  extensionAnalyticsInfo?: ExtensionAnalyticsInfo,
  onCompleteCallback?: (macro: string | undefined) => void,
  updateMacro?: (macro) => void
): Promise<MacroAttributes> {
  return new Promise(async (resolve, reject) => {

    const macroBrowser: any = await loadMacroBrowserResources();

    // filter based on dynamic data such as feature flags
    if (macroFilter) {
      macroBrowser.metadataList = macroBrowser.metadataList.filter(macroFilter);
    }

    if (extensionAnalyticsInfo) {
      extensionAnalyticsInfo.action = "insert";
    }

    const macroBrowserSettings: MacroBrowserSettings =
      await getMacroBrowserSettings(
        macroBrowser,
        config,
        selectedMacro,
        resolve,
        reject,
        extensionAnalyticsInfo,
        onCompleteCallback,
        updateMacro
      );

    try {
      macroBrowser.open({
        ...macroBrowserSettings,
        presetMacroMetadata: {
          ...selectedMacro,
          productContext: {
            "content.id": config.contentId,
            "enrich-content-context-parameters": true,
          },
        },
      });
    } catch (err) {
      fireErrorAnalytics(
        config.contentId,
        "openMacroBrowserForInitialConfiguration",
        extensionAnalyticsInfo?.createAnalyticsEvent,
        err,
        selectedMacro?.attrs?.extensionKey
      );
      reject(err);
    }
  });
}

export * from "./template-extension-handler";
