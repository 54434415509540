import { setActiveTrace } from '@atlassian/react-ufo/experience-trace-id-context';

export function isValidTraceId(traceId?: string): boolean {
	return !!traceId && /^[a-z0-9]+$/i.test(traceId) && traceId.length >= 16;
}

export function initUFOTrace() {
	const traceId = window.__SSR_TRACE_ID__;
	const traceSampled = window.__SSR_TRACE_SAMPLED__;

	// Only enable trace propagation if the trace is sampled.
	// Keep per-request unique traceIds for prod traffic until we have the x-request-id solution.
	// See: https://hello.atlassian.net/wiki/spaces/~71202065d425fcbfb345c9a5639940cc3845bb/pages/4187050054/Trace+Propagation+Across+an+Experience
	if (!traceSampled) {
		return;
	}

	const validTraceId = isValidTraceId(traceId) ? traceId : undefined;
	const validSpanId = validTraceId ? validTraceId.substring(validTraceId.length - 16) : undefined;

	// If the traceId is invalid, we ignore it and don't set the trace context.
	// This would fallback to default behavior where trace context is not propagated.
	if (validTraceId && validSpanId) {
		setActiveTrace(validTraceId, validSpanId, '' /* trace type param is unused */);
	}
}
