import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import { SpacePermissionsSettingsRoute } from './SpacePermissionsSettingsRoute';
import {
	ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSideNav } from '@confluence/breadcrumbs/entry-points/getCompanyHubSideNav';

const DEFAULT_TAB = 'users';

export const AdminCompanyHubPermissionsRoute = ({ params, ...props }: RouteMatch) => {
	const spaceKey = getCompanyHubSpaceKey() || '';

	return <SpacePermissionsSettingsRoute {...props} params={{ spaceKey, ...params }} />;
};

AdminCompanyHubPermissionsRoute.NAVIGATION_PARAMS = ({ name, params: { tab = DEFAULT_TAB } }) => {
	const spaceKey = getCompanyHubSpaceKey() || '';
	return {
		Screen: {
			screenEvent: {
				name: `${tab}HubPermissionsScreen`,
				id: spaceKey,
			},
			pageState: { spaceKey, routeName: name },
		},
		MainLayout: {
			navView: getCompanyHubSideNav(name) ? ADMIN_HOME_ID : CONTAINER_HOME_ID,
			spaceKey,
		},
	};
};
