import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import type { IntlShape } from 'react-intl-next';

import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type { ToolbarItem as ToolbarButton } from '@atlaskit/editor-common/extensions';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';
import { TooltipPrimitive } from '@atlaskit/tooltip';
import { isFedRamp } from '@atlassian/atl-context';

import type { ExtensionKeys } from '../../actions/types';
import { messages } from '../../experience-application/messages';
import failedStateMessages from '../../messages';

import { messages as panelMessages } from './messages';
import type { AIManifest } from './types';

export const TRUST_URL = 'https://www.atlassian.com/trust/atlassian-intelligence';

export const getErrorMessage = (content: string) => {
	switch (content) {
		case 'INPUT_EXCEEDS_TOKEN_LIMIT':
			return failedStateMessages.tokenLimitExceeded;
		case 'INPUT_TOO_SHORT_TO_SUMMARIZE':
			return failedStateMessages.inputTooShortError;
		case 'INPUT_TOO_SHORT_TO_PROCESS':
			return failedStateMessages.inputTooShortError;
		case 'RATE_LIMIT':
			return failedStateMessages.rateLimitEnforced;
		case 'OPENAI_RATE_LIMIT_USER_ABUSE':
			return failedStateMessages.rateLimitEnforced;
		case 'NO_AGENT':
			return failedStateMessages.internalServerError;
		case 'FEATURE_DISABLED_ON_SITE':
			return failedStateMessages.internalServerError;
		case 'FEATURE_DISABLED':
			return failedStateMessages.internalServerError;
		case 'INTERNAL_SERVER_ERROR':
			return failedStateMessages.internalServerError;
		case 'PLUGIN_ERRORED':
			return failedStateMessages.internalServerError;
		case 'HIPAA_CONTENT_DETECTED':
			return failedStateMessages.hipaaContentError;
		case 'EXCEEDING_CONTEXT_LENGTH_ERROR':
			return failedStateMessages.tokenLimitExceeded;
		default:
			return failedStateMessages.apiError;
	}
};

export const getAupViolationMessage = (
	formatMessage: IntlShape['formatMessage'],
): React.ReactNode => {
	return formatMessage(messages.aupViolationMessage, {
		auplink: (chunks: React.ReactNode[]) => (
			<a href="https://www.atlassian.com/legal/acceptable-use-policy" target="_blank">
				{chunks}
			</a>
		),
	});
};

export const getInternalServerErrorMessage = (
	formatMessage: IntlShape['formatMessage'],
): React.ReactNode => {
	return formatMessage(failedStateMessages.internalServerError, {
		link: (chunks: React.ReactNode[]) => (
			<a href="https://status.atlassian.com/" target="_blank">
				{chunks}
			</a>
		),
	});
};

const getContentQualityMessage = (formatMessage: IntlShape['formatMessage']): React.ReactNode => {
	return formatMessage(panelMessages.contentQualityMayVary, {
		link: (chunks: React.ReactNode[]) => (
			<a href={TRUST_URL} target="_blank">
				{chunks}
			</a>
		),
	});
};

export const getRateLimitErrorMessage = (
	formatMessage: IntlShape['formatMessage'],
): React.ReactNode => {
	return formatMessage(failedStateMessages.rateLimitEnforced, {
		link: (chunks: React.ReactNode[]) => (
			<a
				href="https://support.atlassian.com/organization-administration/docs/excessive-use-in-atlassian-intelligence/"
				target="_blank"
			>
				{chunks}
			</a>
		),
	});
};

export const isAIPanelsEnabled = (): boolean => {
	/**
	 * WARNING: Do not remove the isFedRamp check.
	 * This is to ensure that AI functionality is not enabled in FedRamp environments.
	 */
	if (isFedRamp()) {
		return false;
	}
	let aiPanelsEnabled = false;
	if (fg('platform_editor_ai_enable_ai_summary_blocks')) {
		aiPanelsEnabled = true;
	}
	if (fg('platform_editor_ai_enable_ai_action_items_block')) {
		aiPanelsEnabled = true;
	}
	return aiPanelsEnabled;
};

export const getRegenerateToolbarItem = (
	intl: AIManifest['intl'],
	api: AIManifest['api'],
	extensionKey: ExtensionKeys,
	messages: {
		regenerateButtonLabel: {
			id: string;
			defaultMessage: string;
		};
		regenerateButtonTooltip: {
			id: string;
			defaultMessage: string;
		};
	},
): ToolbarButton => {
	return {
		key: 'regenerate-button',
		label: intl.formatMessage(messages.regenerateButtonLabel),
		display: 'icon-and-label',
		tooltip: intl.formatMessage(messages.regenerateButtonTooltip),
		icon: () => import('@atlaskit/icon/glyph/retry').then((mod) => mod.default),
		action: async (adf: ADFEntity) => {
			if (!adf.attrs?.localId || !api || !api.aiExperience) {
				return;
			}
			api.aiExperience.actions.regenerateExtension(api, adf.attrs.localId, extensionKey);
		},
		disabled: !api || !api.aiExperience,
	};
};

export const getFeedbackToolbarItem = (
	intl: AIManifest['intl'],
	api: AIManifest['api'],
	messages: {
		feedbackButtonLabelTooltip: {
			id: string;
			defaultMessage: string;
		};
	},
): ToolbarButton => {
	return {
		key: 'feedback-button',
		label: intl.formatMessage(messages.feedbackButtonLabelTooltip),
		display: 'icon',
		tooltip: intl.formatMessage(messages.feedbackButtonLabelTooltip),
		icon: () => import('@atlaskit/icon/glyph/editor/feedback').then((mod) => mod.default),
		action: async (adf: ADFEntity) => {
			if (!adf.attrs?.localId || !api || !api.aiExperience) {
				return;
			}
			api.aiExperience.actions.triggerAIPanelFeedbackCollector(
				adf?.attrs?.extensionKey,
				adf?.attrs?.parameters?.content,
				intl.locale,
				api?.analytics?.actions?.fireAnalyticsEvent,
			);
		},
		disabled: !api || !api.aiExperience,
	};
};

export const getQualityToolbarItem = (intl: AIManifest['intl']): ToolbarButton => {
	return {
		key: 'quality-info',
		label: '',
		ariaLabel: intl.formatMessage(panelMessages.contentQualityMayVaryLabel),
		display: 'icon',
		tooltip: getContentQualityMessage(intl.formatMessage),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
		tooltipStyle: styled(TooltipPrimitive)({
			backgroundColor: token('elevation.surface.overlay'),
			maxWidth: '220px',
			padding: token('space.150', '12px'),
			borderRadius: token('border.radius.100', '3px'),
			boxShadow: token(
				'elevation.shadow.overlay',
				'0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
			),
		}),
		icon: () => import('@atlaskit/icon/glyph/editor/panel').then((mod) => mod.default),
		action: async () => {
			return new Promise<void>(() => {
				window.open(TRUST_URL, '_blank');
			});
		},
	};
};
