import type { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type { DiffObject, ParagraphChunk } from '../../utils/diff-match-patch/utils';

import { type DocumentSGChecker } from './document-sg-checker';

export type ChunkMetadata = {
	editDistanceRatio?: number;
	wordCountRatio?: number;
	originalContentLength?: number;
};

export type ProactiveAISentence = ParagraphChunk & {
	size: number;
	diffObjects?: DiffObject[];
	needSpellingAndGrammarCheck: boolean;
	needSGCheckForDocChecker?: boolean;
	metadata?: ChunkMetadata;
};

export type ProactiveAIBlock = ParagraphChunk & {
	nodeTypeName: string;
	needSpellingAndGrammarCheck: boolean;
	needSGCheckForDocChecker?: boolean;
	diffObjects?: DiffObject[];
	sentences?: Array<ProactiveAISentence>;
	metadata?: ChunkMetadata;
};

export interface AISpellingGrammarPluginState {
	initialToggleState: boolean;
	isSpellingAndGrammarEnabled: boolean;
	decorationSet: DecorationSet;
	proactiveAIApiUrl: string;
	/**
	 * Blocks are small part of the document that will be
	 *  monitored if affected by transaction or not.
	 * If affected by transaction then new block (or blocks) will be
	 *  created based on transaction.
	 * New blocks will have updated positions, text, and diffObjects
	 *  will be removed from it as new block will be checked for
	 *  S+G after sometime.
	 * Once new blocks are checked for S+G, block's diffObjects will
	 *  be stored in it.
	 * Then from all the diffObjects stored in blocks, we will
	 *  update allDiffObjects and decorationSet.
	 */
	proactiveAIBlocks?: ProactiveAIBlock[];
	dismissedWords: Set<string>;
	toggleCount: number;
	insertionCount: number;
	splitParagraphIntoSentences: boolean;
	selectedID?: string;
	documentSGChecker?: DocumentSGChecker;
	/**
	 * A count of how many times the doc has changed up to an arbitrary amount
	 * e.g. up to 5 doc changes, then we stop updating this value in the state
	 */
	docChangeCountUntilLimit: number;
	/**
	 * A flag to indicate if the first initiated event has been sent when the plugin is on by default
	 * if it's true - we don't send it again
	 * if it's false - we send the analytics event
	 */
	isFirstInitiatedEventSent: boolean;
}

export enum ACTIONS {
	UPDATE_PLUGIN_STATE,
	TOGGLE_SPELLING_AND_GRAMMAR_ENABLED,
	DISABLE_NEED_SPELLING_AND_GRAMMAR,
	UPDATE_CHUNK_METADATA,
	START_SPELLING_AND_GRAMMAR,
}

export type SpellingGrammarAction =
	| {
			type: ACTIONS.UPDATE_PLUGIN_STATE;
			data: Partial<AISpellingGrammarPluginState>;
	  }
	| {
			type: ACTIONS.TOGGLE_SPELLING_AND_GRAMMAR_ENABLED;
			data: {
				toggleCount: number;
			};
	  }
	| {
			type: ACTIONS.DISABLE_NEED_SPELLING_AND_GRAMMAR;
			data: {
				skippedChunkIds: string[];
			};
	  }
	| {
			type: ACTIONS.UPDATE_CHUNK_METADATA;
			data: {
				chunkId: string;
				metadata: ChunkMetadata;
			};
	  }
	| {
			type: ACTIONS.START_SPELLING_AND_GRAMMAR;
	  };
