import { fg } from '@confluence/feature-gating';
import { query } from '@confluence/query-preloader-tools';
import {
	getSSRFeatureFlag,
	getSSRRenderInputs,
	ssrPrepareErrorsClient,
} from '@confluence/ssr-utilities';

import { fetchORS, getInitialState, getSmartLinks } from './index';

import { ContentSmartLinksQuery } from './ContentSmartLinksQuery.graphql';
import type {
	ContentSmartLinksQuery as ContentSmartLinksQueryType,
	ContentSmartLinksQueryVariables as ContentSmartLinksQueryVariablesType,
} from './__types__/ContentSmartLinksQuery';
import {
	MAX_BATCH_ORS_SMARTLINKS,
	DEFAULT_ORS_TIMEOUT,
	MAX_CONCURRENT_ORS_CALLS,
} from './preloadContentSmartLinksConstants';

declare global {
	interface Window {
		__SSR_SMARTLINKS__?: { [key: string]: any } | {};
	}
}

/*
 * preloadContentSmartLinks tracks the entire preloaded chain of queries needed to SSR Content Smart Links. This consists of two calls:
 * 1. ContentSmartLinksQuery, a GQL call to fetch SmartLinks URLs and
 * 2. A call to ORS to resolve the metadata, which uses the links returned from the previous call
 * The entirety of this chain is currently best effort, which means that SmartLinks will either resolve ALL
 * links by the time the longest preloaded query resolves or NONE.
 *
 * If a timeout is provided, Smartlinks will try and resolve as many links as possible
 * within the provided timeout.
 */
export const preloadContentSmartLinks = async (contentId: string) => {
	const { customURLs, userContext } = getSSRRenderInputs();
	// If we don't receive a URL - no reason to try and run queries
	let objectResolverURL = customURLs?.['object-resolver-service'];
	if (!objectResolverURL) {
		return;
	}

	// Call cc-graphql and get SmartLink URLs for the current page
	// begin marking blocking query time to determine remaining budget for ORS call
	const contentSmartLinksQueryStartTime = performance.now();
	const res = await query<ContentSmartLinksQueryType, ContentSmartLinksQueryVariablesType>({
		query: ContentSmartLinksQuery,
		variables: {
			contentId,
		},
	});

	// Loop through query data and pull out smart links
	const contentSmartLinksQueryDuration = performance.now() - contentSmartLinksQueryStartTime;
	const smartLinks = getSmartLinks(res);

	// If there are no valid SmartLinks on the page - exit
	if (!smartLinks?.length) {
		return;
	}
	// Save number of SmartLinks to send as BM Event
	window['__SSR_NUMBER_OF_SMARTLINKS__'] = smartLinks.length;

	// Send smart links to linking platform
	// EX: [{resourceUrl: "https://pug.jira-dev.com/wiki/people/team/3a9665f3-c92b-423a-88bd-9410c1180058"}]

	// If there is still budget remaining after ContentSmartLinksQuery to make an ORS call,
	// pass the remaining time as a param to the ORS resolver call
	const timeout = getSSRFeatureFlag('confluence.ssr.preload.query.timeout') || DEFAULT_ORS_TIMEOUT;
	if (timeout) {
		const errorBudgetForORSResolver = Math.round(timeout - contentSmartLinksQueryDuration);
		if (errorBudgetForORSResolver > 0) {
			objectResolverURL += `?timeout=${errorBudgetForORSResolver}`;
		} else {
			return;
		}
	}

	try {
		const orsCalls: Promise<any>[] = [];
		if (fg('confluence_ssr_batch_ors_smartlinks')) {
			const limitedSmartlinks = smartLinks.slice(
				0,
				MAX_BATCH_ORS_SMARTLINKS * MAX_CONCURRENT_ORS_CALLS,
			);

			for (let i = 0; i < limitedSmartlinks.length; i += MAX_BATCH_ORS_SMARTLINKS) {
				const chunk = limitedSmartlinks.slice(i, i + MAX_BATCH_ORS_SMARTLINKS);
				orsCalls.push(
					fetchORS({
						url: objectResolverURL,
						body: JSON.stringify(chunk),
						userContext,
					}).then((orsRes) => orsRes.json()),
				);
			}
		} else {
			orsCalls.push(
				fetchORS({
					url: objectResolverURL,
					body: JSON.stringify(smartLinks),
					userContext,
				}).then((orsRes) => orsRes.json()),
			);
		}

		const __SSR_SMARTLINKS__ = await Promise.all(orsCalls).then((responses) => responses.flat());
		// Format response to fit SmartCard Provider storeOptions initial state
		const initialState = getInitialState(__SSR_SMARTLINKS__);
		// Save correctly formatted response to window obj so it can be used on SSR and SPA (no duplicate calls)
		window['__SSR_SMARTLINKS__'] = initialState;
	} catch (e) {
		ssrPrepareErrorsClient.submitError(
			new Error(`ORS returned error on resolving SmartLinks: ${e.message}`),
		);
	}
};
