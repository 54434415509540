import type { ExperienceName } from '@confluence/experience-tracker';
import {
	EDIT_PAGE_EXPERIENCE,
	HOME_EXPERIENCE,
	SPACE_OVERVIEW_EXPERIENCE,
	VIEW_PAGE_EXPERIENCE,
	WHITEBOARD_EXPERIENCE,
	DATABASE_EXPERIENCE,
	EXTERNAL_SHARE_EXPERIENCE,
	CONTENT_HISTORY_EXPERIENCE,
	SPACE_PAGES_EXPERIENCE,
	EDIT_PAGE_EMBED_EXPERIENCE,
	SPACE_BLOGS_EXPERIENCE,
	EMBED_EXPERIENCE,
	LIVE_PAGE_VIEW_EXPERIENCE,
	FRONT_COVER_LOAD_EXPERIENCE,
} from '@confluence/experience-tracker';
import { preloadAppNavigation } from '@confluence/app-navigation/entry-points/preloadAppNavigation';
import { preloadSPAViewContext } from '@confluence/spa-view-context/entry-points/preloadSPAViewContext';
import { preloadGlobalOperations } from '@confluence/global-operations/entry-points/preloadGlobalOperations';
import { preloadLocalStorage } from '@confluence/storage-manager/entry-points/preloadLocalStorage';
import { getExtensionManifest } from '@confluence/fabric-extension-lib/entry-points/getExtensionManifest';
import { preloadMacrosSPA } from '@confluence/fabric-extension-handlers/entry-points/preloadMacrosSPA';
import { preloadSuperBatchData } from '@confluence/wrm/entry-points/preloadSuperBatchData';
import { preloadCookiePreferences } from '@confluence/cookies-consent-banner/entry-points/preloadCookiePreferences';
import { ssrPrepareErrorsClient } from '@confluence/ssr-utilities';
import { preloadAdminAnnouncementBanner } from '@confluence/admin-announcement-banner/entry-points/preloadAdminAnnouncementBanner';
import { preloadStorageEnforcementBanner } from '@confluence/storage-enforcement/entry-points/preloadStorageEnforcementBanner';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import {
	getPreloaderFnContext,
	initializePreloaderFnContext,
} from '@confluence/query-preloader-tools';
import { setFallbackAtlExperience } from '@confluence/graphql';
import { preloadUserPreferencesForSSR } from '@confluence/storage-manager/entry-points/preloadUserPreferencesForSSR';

import { preloadViewPageRoute } from './preloadViewPageRoute';
import { preloadSpaceOverviewRoute } from './preloadSpaceOverviewRoute';
import { preloadWhiteboardRoute } from './preloadWhiteboardRoute';
import { preloadDatabaseRoute } from './preloadDatabaseRoute';
import { preloadEmbedRoute } from './preloadEmbedRoute';
import { preloadEditPageRoute } from './preloadEditPageRoute';
import { preloadEditPageRoutePostSSR } from './preloadEditPageRoutePostSSR';
import { preloadSpacePagesRoute } from './preloadSpacePagesRoute';
import { preloadContentHistoryRoute } from './preloadContentHistoryRoute';
import { preloadSpaceBlogsRoute } from './preloadSpaceBlogsRoute';
import { preloadHomeRoute } from './preloadHomeRoute';
import {
	matchHome,
	matchViewPage,
	matchSpaceOverview,
	matchCompanyHub,
	matchFabricEditor,
	matchExternalShare,
	matchSpacePages,
	matchWhiteboardPage,
	matchDatabasePage,
	matchEmbedPage,
	matchContentHistory,
	matchEmbeddedEditor,
	matchSpaceBlogs,
} from './matchRoutes';

export { initializePreloaderFnContext };

/**
 * Preload the extension manifest and legacy macros whenever these declared to be preloaded
 * by the server-side code. Since View Page, Space Overview and Editor are the only routes
 * using extensions we can preload it only for those.
 *
 * WARN: this function is called always, no matter whether server-hydrated Apollo cache exists
 * or not, as data preloaded here are not expected to be available from server even after SSR.
 */
export async function preloadExtensionsData(url: string) {
	const match = matchRoute(url);
	if (
		!process.env.REACT_SSR &&
		(match?.experience === VIEW_PAGE_EXPERIENCE ||
			match?.experience === SPACE_OVERVIEW_EXPERIENCE ||
			match?.experience === EDIT_PAGE_EXPERIENCE ||
			match?.experience === LIVE_PAGE_VIEW_EXPERIENCE)
	) {
		return await Promise.all([
			getExtensionManifest({ spaceKey: match.params.spaceKey }),
			getPreloaderFnContext().then(({ featureFlags }) => {
				const shouldPreloadSpaceDetails =
					!!featureFlags['confluence.frontend.ecosystem.access.narrrowing'];
				const isSpaceAliasFFEnabled = Boolean(featureFlags['confluence.frontend.space.alias']);
				const spaceKey = match.params.spaceKey;

				if (shouldPreloadSpaceDetails) {
					return preloadSpaceDetail(spaceKey, isSpaceAliasFFEnabled);
				}

				return Promise.resolve({});
			}),
			preloadSuperBatchData(),
			preloadMacrosSPA(),
		]);
	}
}

/**
 *
 *     _    _  ___ ______ _   _ _____ _   _ _____
 *    | |  | |/ _ \| ___ | \ | |_   _| \ | |  __ \
 *    | |  | / /_\ | |_/ |  \| | | | |  \| | |  \/
 *    | |/\| |  _  |    /| . ` | | | | . ` | | __
 *    \  /\  | | | | |\ \| |\  |_| |_| |\  | |_\ \
 *     \/  \/\_| |_\_| \_\_| \_/\___/\_| \_/\____/
 *
 *    All the preloader function calls should be chained to the returning promise.
 *    preloadQueryBasedOnRoute should ONLY be resolved when ALL the queries have been executed.
 *
 */
export async function preloadQueryBasedOnRoute(
	url,
	isTransition = false,
	previousMatch = null,
): Promise<any> {
	const commonTasks: Promise<any>[] = [];
	const match = matchRoute(url);
	if (match?.experience) {
		setFallbackAtlExperience(match.experience);
	}

	/**
	 * There are synchronous operations in preparing session data.
	 * All queries that independent to session data should be fired first.
	 */
	if (!isTransition) {
		commonTasks.push(
			getPreloaderFnContext().then(({ environment }) => preloadCookiePreferences(environment)),
		);
		if (match?.experience !== EXTERNAL_SHARE_EXPERIENCE) {
			// external share doesn't have navigation, preloadSPAViewContext returns only errors
			commonTasks.push(
				preloadSPAViewContext(),
				getPreloaderFnContext().then((context) => {
					return Promise.all([preloadLocalStorage(context), preloadAppNavigation()]);
				}),
				preloadGlobalOperations(),
				getPreloaderFnContext().then(({ edition, isLicensed, isLoggedIn, featureFlags }) => {
					const upperCaseEdition = edition?.toUpperCase();
					if (
						upperCaseEdition === 'PREMIUM' ||
						(Boolean(featureFlags['confluence.frontend.admin-announcement-banner.standard']) &&
							upperCaseEdition === 'STANDARD')
					) {
						preloadAdminAnnouncementBanner();
					} else if (upperCaseEdition === 'FREE') {
						if (isLicensed && isLoggedIn) {
							preloadStorageEnforcementBanner();
						}
					}
				}),
			);
		}
	}

	if (!match) {
		// Following code are specific to each route.
		// If we don't get a match here then there is nothing interesting
		return Promise.resolve();
	}

	let routeTasks: Promise<any> | null = null;
	if (match.experience === VIEW_PAGE_EXPERIENCE) {
		routeTasks = preloadViewPageRoute(match, url, isTransition, previousMatch);
	} else if (
		match.experience === SPACE_OVERVIEW_EXPERIENCE ||
		match.experience === FRONT_COVER_LOAD_EXPERIENCE
	) {
		routeTasks = preloadSpaceOverviewRoute(match, url);
	} else if (match.experience === WHITEBOARD_EXPERIENCE) {
		routeTasks = preloadWhiteboardRoute(match, url, isTransition);
	} else if (match.experience === DATABASE_EXPERIENCE) {
		routeTasks = preloadDatabaseRoute(match, url, isTransition);
	} else if (match.experience === EMBED_EXPERIENCE) {
		routeTasks = preloadEmbedRoute(match, url, isTransition);
	} else if (match.experience === EDIT_PAGE_EMBED_EXPERIENCE) {
		routeTasks = preloadUserPreferencesForSSR({
			isLicensed: false,
			themeState: match.query?.themeState as string,
		});
	} else if (match.experience === EDIT_PAGE_EXPERIENCE) {
		routeTasks = preloadEditPageRoute(match, url, isTransition);
	} else if (match.experience === HOME_EXPERIENCE) {
		routeTasks = preloadHomeRoute(match);
	} else if (match.experience === SPACE_PAGES_EXPERIENCE) {
		routeTasks = preloadSpacePagesRoute(match);
	} else if (match.experience === CONTENT_HISTORY_EXPERIENCE) {
		routeTasks = preloadContentHistoryRoute(match);
	} else if (match.experience === SPACE_BLOGS_EXPERIENCE) {
		routeTasks = preloadSpaceBlogsRoute(match);
	}

	return Promise.all([routeTasks, ...commonTasks]).catch((e) => {
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line no-console
			console.error(`Error when preloading query:`, e.stack);
		}
		ssrPrepareErrorsClient.submitError(e);
	});
}

export async function preloadQueryBasedOnRoutePostSSR(pageUrl: string) {
	const match = matchRoute(pageUrl);
	if (!match) {
		return;
	}

	if (match.experience === EDIT_PAGE_EXPERIENCE) {
		return preloadEditPageRoutePostSSR(match, pageUrl);
	}
}
function matchRoute(url: string) {
	let match: ReturnType<typeof matchViewPage>;
	let experience: ExperienceName | undefined;

	if ((match = matchViewPage(url))) {
		experience = VIEW_PAGE_EXPERIENCE;
	} else if ((match = matchWhiteboardPage(url))) {
		experience = WHITEBOARD_EXPERIENCE;
	} else if ((match = matchSpaceOverview(url))) {
		experience = SPACE_OVERVIEW_EXPERIENCE;
	} else if ((match = matchCompanyHub(url))) {
		experience = FRONT_COVER_LOAD_EXPERIENCE;
	} else if ((match = matchHome(url))) {
		experience = HOME_EXPERIENCE;
	} else if ((match = matchFabricEditor(url))) {
		experience = EDIT_PAGE_EXPERIENCE;
	} else if ((match = matchExternalShare(url))) {
		experience = EXTERNAL_SHARE_EXPERIENCE;
	} else if ((match = matchSpacePages(url))) {
		experience = SPACE_PAGES_EXPERIENCE;
	} else if ((match = matchContentHistory(url))) {
		experience = CONTENT_HISTORY_EXPERIENCE;
	} else if ((match = matchEmbeddedEditor(url))) {
		experience = EDIT_PAGE_EMBED_EXPERIENCE;
	} else if ((match = matchSpaceBlogs(url))) {
		experience = SPACE_BLOGS_EXPERIENCE;
	} else if ((match = matchDatabasePage(url))) {
		experience = DATABASE_EXPERIENCE;
	} else if ((match = matchEmbedPage(url))) {
		experience = EMBED_EXPERIENCE;
	}

	return (
		match && {
			...match,
			experience,
		}
	);
}

export async function preloadQueriesForSPAHydration() {
	const pageUrl = window.location.toString();

	// Extensions Manifest and extensions data (when preloading is requested for them)
	// are heavy and should not be loaded on server side and embedded into HTML.
	void preloadExtensionsData(pageUrl);

	if (window.__SSR_RENDERED__) {
		void preloadQueryBasedOnRoutePostSSR(pageUrl);
	} else {
		void preloadQueryBasedOnRoute(pageUrl);
	}
}
