export const smartButtonExtensionType = 'com.atlassian.automation.smart-button';

export const SMART_BUTTON_EXTENSION_FF =
	'confluence.frontend.automation.smart-buttons-extension_qshsa';

export const SMART_BUTTON_TEMPLATES_FF = 'confluence_frontend_more_smart_button_templates';

export const SMART_BUTTON_CREATE_JIRA_ISSUE_FG = 'a4c_smart_button_create_issue_template';

export const SMART_BUTTON_IN_BLUEPRINTS_FF = 'smart_buttons_in_blueprints';
