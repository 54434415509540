/* GeneratedContentHash: 4c1c53b0d6e39078ed21c3a4c78b9082084e9d10ab1edf467d49263048e51118 */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
import { lazy } from 'react';

export default {
	'test-message-inapp': lazy(
		() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--test-message-inapp/placements/in-app/screen-space-flags/render'
			),
	),
};
