import { preloadUserPreferencesForSSR } from '@confluence/storage-manager/entry-points/preloadUserPreferencesForSSR';
import { preloadPersonalSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadIntentBasedOnboarding } from '@confluence/home/entry-points/preloadIntentBasedOnboarding';
import { preloadHome } from '@confluence/home/entry-points/preloadHome';
import { preloadCreateFromTemplate } from '@confluence/home/entry-points/preloadCreateFromTemplate';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export const preloadHomeRoute = async (match: RouteMatch) => {
	const { featureFlags, userId, cloudId, isLicensed, accessStatus } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];

	const canAccessHome =
		accessStatus !== 'ANONYMOUS_ACCESS' && accessStatus !== 'UNLICENSED_AUTHENTICATED_ACCESS';

	if (isLicensed && canAccessHome) {
		tasks.push(
			preloadHome({
				cloudId,
				isLicensed,
				userId,
				matchParams: match.params,
				isSpaceAliasFFEnabled: Boolean(featureFlags['confluence.frontend.space.alias']),
				isMyVisitedSpacesEnabled: Boolean(
					featureFlags['confluence.frontend.recently-viewed-plugin.spaces'],
				),
				isWhiteboardViewedEnabled: Boolean(featureFlags['confluence.frontend.whiteboard.enable']),
				isDatabaseEnabled: Boolean(featureFlags['confluence.frontend.databases.enabled']),
				isSmartLinkEmbedEnabled: Boolean(featureFlags['confluence.frontend.embeds.enabled']),
			}),
		);
	}

	const shouldPreloadSpaceDetails = featureFlags['confluence.frontend.ecosystem.access.narrrowing'];
	if (shouldPreloadSpaceDetails) {
		tasks.push(preloadPersonalSpaceDetail());
	}

	if (process.env.REACT_SSR) {
		tasks.push(preloadUserPreferencesForSSR({ isLicensed }));
	}

	tasks.push(preloadIntentBasedOnboarding({ userId }), preloadCreateFromTemplate({ userId }));

	return Promise.all(tasks);
};
