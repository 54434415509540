import React from 'react';

import { isFedRamp } from '@atlassian/atl-context';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { SPACE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE } from '@confluence/confluence-analytics/entry-points/analyticsConstants';
import {
	SPACE_ANALYTICS_PERMISSIONS_PLUGIN,
	FEDRAMP_SPACE_ANALYTICS_PERMISSIONS_PLUGIN,
	SPACE_PERMISSIONS_SETTINGS,
} from '@confluence/named-routes';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import type { RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager';

// TODO - CCIR-11: Remove this entire route and replace with SPACE_PERMISSIONS_SETTINGS once the
// Analytics native UI migration is complete

export const SpaceAnalyticsPermissionsRoute = ({ params: { spaceKey }, search }: RouteMatch) => {
	const isAnalyticsUiMigrationEnabled = FeatureGates.checkGate(
		'confluence_frontend_analytics_ui_migration',
	);
	if (!isAnalyticsUiMigrationEnabled) {
		const isFedrampEnv = isFedRamp();

		const url = isFedrampEnv
			? FEDRAMP_SPACE_ANALYTICS_PERMISSIONS_PLUGIN.toUrl({ spaceKey })
			: SPACE_ANALYTICS_PERMISSIONS_PLUGIN.toUrl({ spaceKey });
		return <Redirection href={`${url}${search}`} />;
	}

	// The Space Analytics Permissions is actually displayed in a tab on the Space Permissions
	// Settings page, so we redirect to the Space Permissions page with the Analytics tab selected.
	// When the native UI migration is complete, we can go directly to the Space Permissions Settings
	// page and we won't need this route at all - we can then remove this route and replace any
	// references to it with the SPACE_PERMISSIONS_SETTINGS route.
	return (
		<Redirection
			href={`${SPACE_PERMISSIONS_SETTINGS.toUrl({ spaceKey, tab: 'analytics' })}${search}`}
		/>
	);
};

SpaceAnalyticsPermissionsRoute.NAVIGATION_PARAMS = ({
	name,
	params: { spaceKey },
}: RouteMatch) => ({
	Screen: {
		screenEvent: { name: SPACE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE, id: spaceKey },
		pageState: { routeName: name, spaceKey },
	},
	MainLayout: {
		spaceKey,
		navView: CONTAINER_HOME_ID,
	},
});
