import { LoadableAfterPaint, LoadableBackground, LoadableLazy } from '@confluence/loadable';

export { EMPTY_SPACE, isSourceSpaceValid } from './CopySpacePermissionsSelector';

export { type SourceSpaceSelectorProps } from './SourceSpaceSelector';

export { Option } from './Option';

export const CopySpacePermissionsForExistingSpacePortal = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CopySpacePermissionsForExistingSpacePortal" */ './CopySpacePermissionsForExistingSpacePortal'
			)
		).CopySpacePermissionsForExistingSpacePortal,
	name: 'CopySpacePermissionsForExistingSpacePortal',
});

export const CopySpacePermissionsForLegacyNewSpace = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CopySpacePermissionsForLegacyNewSpace" */ './CopySpacePermissionsForLegacyNewSpace'
			)
		).CopySpacePermissionsForLegacyNewSpace,
	name: 'CopySpacePermissionsForLegacyNewSpace',
});

export const SourceSpaceSelector = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SourceSpaceSelector" */ './SourceSpaceSelector'))
			.SourceSpaceSelector,
	name: 'SourceSpaceSelector',
});
