import gql from 'graphql-tag';

import { contentAppearancePublishedFragment } from '@confluence/content-appearance/entry-points/fragments';

export const ContentRendererExternalQuery = gql`
	query ContentRendererExternalQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			body {
				atlas_doc_format {
					value
				}
			}
			type
			version {
				confRev
			}
			...contentAppearancePublishedFragment
		}
	}

	${contentAppearancePublishedFragment}
`;
