import React, { useMemo, useContext } from 'react';
import { SPAViewContext } from '@confluence/spa-view-context';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { useSessionData, type SessionDataType } from '@confluence/session-data';
import { useRouteDataRef } from '@confluence/route-manager';

import {
	type PostOfficeEnvironmentConfig,
	PostOfficeProvider as PostOfficeBaseProvider,
	usePostOfficeRouteUpdate,
} from '@atlassian/post-office-context';

const getWorkspaceARI = ({ cloudId, activationId }: { cloudId: string; activationId: string }) => {
	return `ari:cloud:confluence:${cloudId}:workspace/${activationId}`;
};

const CONFLUENCE_PRODUCT = 'confluence';

const DEFAULT_POST_OFFICE_CONTEXT = {
	product: CONFLUENCE_PRODUCT,
	locale: 'en',
};

const ConfluencePostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { activationId, cloudId, environment, locale, orgId, edition } = useSessionData();

	const workspaceAri = getWorkspaceARI({ activationId, cloudId });

	const context = useMemo(
		() => ({
			...DEFAULT_POST_OFFICE_CONTEXT,
			locale,
			orgId,
			isSiteAdmin,
			tenantId: cloudId,
			workspaceAri,
			workspaceId: activationId,
			subproduct: undefined,
			productEdition: edition || undefined,
		}),
		[activationId, cloudId, isSiteAdmin, locale, orgId, edition, workspaceAri],
	);

	const env = useMemo(
		() => transformConfluenceEnvToPostOfficeEnvironment(environment),
		[environment],
	);

	return (
		<PostOfficeBaseProvider context={{ current: context }} environment={{ env }}>
			{children}
		</PostOfficeBaseProvider>
	);
};

export const PostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	const isPostOfficeEnabled =
		FeatureGates.checkGate('post-office-screen-space-overlay-confluence') ||
		FeatureGates.checkGate('post_office_app_switcher_discovery_confluence');

	return isPostOfficeEnabled ? (
		<ConfluencePostOfficeProvider>{children}</ConfluencePostOfficeProvider>
	) : (
		<>{children}</>
	);
};

const ReactResourceRouterListener = ({ children }: { children: React.ReactNode }) => {
	const routeDataRef = useRouteDataRef();

	usePostOfficeRouteUpdate([
		routeDataRef?.current?.href ?? '',
		routeDataRef.current?.routeName ?? '',
	]);

	return <>{children}</>;
};

export const PostOfficeRouterListener = ({ children }: { children: React.ReactNode }) => {
	const isScreenSpaceFlagsEnabled = FeatureGates.checkGate(
		'post-office-screen-space-overlay-confluence',
	);

	return isScreenSpaceFlagsEnabled ? (
		<ReactResourceRouterListener>{children}</ReactResourceRouterListener>
	) : (
		<>{children}</>
	);
};

export const transformConfluenceEnvToPostOfficeEnvironment = (
	environment: SessionDataType['environment'] | null,
): PostOfficeEnvironmentConfig['env'] => {
	switch (environment) {
		case 'STAGING':
			return 'staging';
		case 'PRODUCTION':
			return 'production';
		case 'DEVELOPMENT':
			return 'local';
		default:
			return 'local';
	}
};
export { ScreenSpaceFlagsPlacement } from './screen-space-flags-placement';
