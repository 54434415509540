import type { Scopes } from '@atlassian/post-office-context';

export const scopes: Scopes = [
	{
		name: 'all',
		when: {
			route: {
				pathname: {
					matches: '.*',
				},
			},
		},
	},
	{
		name: 'jira/all',
		when: {
			context: { product: 'jira' },
		},
	},
	{
		name: 'jira/kanban',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'boards',
				},
			},
		},
	},
	{
		name: 'confluence/all',
		when: {
			context: { product: 'confluence' },
		},
	},
	{
		name: 'pop3/all',
		when: {
			context: { product: 'pop3' },
		},
	},
	{
		name: 'admin/all',
		when: {
			context: {
				product: 'admin',
			},
		},
	},
	{
		name: 'pop3/send-it',
		when: {
			context: { product: 'pop3' },
			route: {
				pathname: {
					matches: 'send-it',
				},
			},
		},
	},
	{
		name: 'admin/products',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'products',
				},
			},
		},
	},
];
