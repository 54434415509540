import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';

import { useAnalyticsEvents, AnalyticsListener } from '@atlaskit/analytics-next';

import { useRouteName } from '@confluence/route-manager/entry-points/RouteState';
import { COMPANY_HUB } from '@confluence/named-routes';
import { useSessionData } from '@confluence/session-data';
import { useSpaceId } from '@confluence/space-utils';

const isOnCompanyHubRouteArgs: Parameters<typeof useRouteName>[0] = {
	selector: (routeName) => COMPANY_HUB.name === routeName,
};

/**
 * The Goal of the Company Hub Analytics Wrapper is to catch 'link clicked' and 'button clicked' analytic events that originate from Company Hub pages.
 * When detecting an event with the matching criteria, it re-emits the 'companyHubLink clicked' event which goes on to be ingested by Druid
 *
 * See here for the options considered in this implementation: https://hello.atlassian.net/wiki/spaces/CLI/pages/3971845759/Capturing+Hub+Smartlinks+Links+Click+DACI
 * Additionally, see here for how 'companyHubLink clicked' information: https://hello.atlassian.net/wiki/spaces/CLI/pages/3991244014/Company+Hub+Link+Clicked+Event+Information
 *
 * Maintaining Team: Company Hub
 */
export const CompanyHubAnalyticsWrapper = ({ children }: { children: React.ReactNode }) => {
	const isOnCompanyHubRoute = useRouteName(isOnCompanyHubRouteArgs);
	const spaceId = useSpaceId();
	const { activationId, cloudId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleAnalyticCaught: ComponentProps<typeof AnalyticsListener>['onEvent'] = useCallback(
		async (event, channel) => {
			if (
				event?.payload?.action === 'clicked' ||
				(event?.payload?.actionSubject === 'smartLink' &&
					event?.payload?.action === 'renderSuccess')
			) {
				await import(
					/* webpackChunkName: "loadable-background" */ './companyHubAnalyticsHandleEventCaught'
				).then(({ handleEventCaught }) => {
					handleEventCaught(
						event,
						createAnalyticsEvent,
						!!isOnCompanyHubRoute,
						activationId,
						cloudId,
						channel,
						spaceId,
					);
				});
			}
		},
		[createAnalyticsEvent, isOnCompanyHubRoute, activationId, spaceId, cloudId],
	);

	if (!isOnCompanyHubRoute) {
		return <>{children}</>;
	}

	return (
		<AnalyticsListener channel="*" onEvent={handleAnalyticCaught}>
			{children}
		</AnalyticsListener>
	);
};
