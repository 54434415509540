import type { MediaClientConfig } from '@atlaskit/media-core';
import type { UploadRejectionData } from '@atlaskit/media-picker';

export type LinkCardsParameters = TextCardsParameters;

type LinkCardsCommonParameters = {
	size: CardSizes;
	alignment: CardAlignment;
	cards: Card[];
	extensionTitle: string;
};

export type TextCardsParameters = LinkCardsCommonParameters & {
	type: CardType.TEXT;
	isPublishDateShown: boolean;
	isAvatarShown: boolean;
};

export enum CardSizes {
	EXTRA_SMALL = 'extraSmall',
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
	HERO = 'hero',
}

export enum CardAlignment {
	LEFT = 'left',
	CENTER = 'center',
}

export enum CardType {
	TEXT = 'text',
}

export enum ImagePosition {
	TOP = 'top',
	MIDDLE = 'middle',
	BOTTOM = 'bottom',
}

export type Card = {
	cardId: string;
	link: string;
	isLinkUserEdited?: boolean;
	title?: string;
	isTitleUserEdited?: boolean;
	description?: string;
	isDescriptionUserEdited?: boolean;
	imageSrc?: string;
	imagePosition?: ImagePosition;
	imageAltText?: string;
	confluencePageId?: string;
};

export type MediaConfigType = {
	collection: string | undefined;
	authProvider: MediaClientConfig['authProvider'];
	onUploadRejection: ((data: UploadRejectionData) => boolean) | undefined;
} | null;

export type ResolvedLinkDataObject = {
	data: {
		summary?: string;
		name?: string;
	};
	meta: {
		objectId?: string;
	};
};
