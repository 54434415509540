import React, { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import {
	defaultPostOfficeContextValue,
	InternalPostOfficeContextProvider,
} from '../post-office-context';
import type { PostOfficeContextValue } from '../post-office-context/types';
import {
	defaultEnvironmentValues,
	PostOfficeEnvironmentProvider,
} from '../post-office-environment';
import { type PostOfficeEnvironmentValues } from '../post-office-environment/types';
import { RouteListenerProvider } from '../post-office-route/listener';
import { PostOfficeScreenContextProvider } from '../post-office-screen-context';
import { useStableObject } from '../util/use-stable-object';

import { createMessagePlacementValues, MessagePlacementProvider } from './message-placement';
import type { PostOfficeProviderProps } from './types';
import { generateEnvironmentConfigWithOverrides } from './util/get-environment-with-overrides';

/**
 * A provider component for managing the context and configuration of all post office placements.
 * This should only appear once at a high level in your product
 *
 * @component
 * @param {Object} props - The properties passed to the PostOfficeProvider component.
 * @param {PostOfficeContextConfig} props.context - Configuration related to the current post office context,
 * including any relevant state or settings specific to the current session or user.
 * @param {PostOfficeEnvironmentConfig} props.environment - Configuration related to the environment
 * the application is currently running in. This includes the environment type (e.g., development, production)
 * and any optional remote module configuration or environment-specific overrides.
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 *
 * **Example Usage:**
 * ```jsx
 * <PostOfficeProvider
 *   context={{ current: { ... } }}
 *   environment={{
 *     env: "production",
 *     overrides: { ... }
 *   }}
 * >
 *   <YourApp />
 * </PostOfficeProvider>
 * ```
 */
export const PostOfficeProvider = ({
	environment: envWithOverrides,
	context,
	children,
}: PostOfficeProviderProps): JSX.Element => {
	const stableEnvironment: PostOfficeEnvironmentValues =
		useStableObject<PostOfficeEnvironmentValues>(
			generateEnvironmentConfigWithOverrides(defaultEnvironmentValues, envWithOverrides),
		);

	const stableContext: PostOfficeContextValue = useStableObject<PostOfficeContextValue>(
		composeConfig(defaultPostOfficeContextValue, context.current),
	);

	const memoizedMessagePlacementValues = useMemo(() => createMessagePlacementValues(), []);

	return (
		<PostOfficeEnvironmentProvider value={stableEnvironment}>
			<InternalPostOfficeContextProvider value={stableContext}>
				<PostOfficeScreenContextProvider>
					<MessagePlacementProvider value={memoizedMessagePlacementValues}>
						<RouteListenerProvider>{children}</RouteListenerProvider>
					</MessagePlacementProvider>
				</PostOfficeScreenContextProvider>
			</InternalPostOfficeContextProvider>
		</PostOfficeEnvironmentProvider>
	);
};

const composeConfig = <T extends Record<string, unknown>>(
	base: T,
	...rest: (Partial<Record<keyof T, unknown>> | undefined)[]
): T => rest.reduce<T>((a, v) => merge(a, v), cloneDeep(base));
