import { usePostOfficeContext } from '@atlassian/post-office-context';
import { createUsePlacement } from '@post-office/placement-common';
import { type ErrorState } from '@post-office/placement-common';
import { usePlacementContext } from '@post-office/placement-context';
import { usePlacementFetchingContext } from '@post-office/placement-data';
import { type SerializableRecord } from '@post-office/serializable-record';
import React from 'react';

import { type Config } from './types';

export const usePlacementApi = <T extends SerializableRecord>(path: string, config?: Config) => {
	const { placementId } = usePlacementContext();
	const context = usePostOfficeContext();
	const fetcher = usePlacementFetchingContext();

	const placementApi = React.useMemo(() => {
		if (!placementId) {
			return (): ErrorState => ({
				isLoading: false,
				data: undefined,
				error: new Error('No placementId found, make this is used as a child of PlacementProvider'),
			});
		}

		return createUsePlacement(placementId, fetcher);
	}, [placementId, fetcher]);

	return placementApi<T, SerializableRecord>(path, context, config?.requestOptions, {
		throttleTime: config?.throttleTime,
		isEnabled: config?.autoRefetch?.isEnabled,
		deps: [config?.autoRefetch?.updateKey],
	});
};
