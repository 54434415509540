import FeatureGates from '@atlaskit/feature-gate-js-client';

import { addFeatureFlagAccessed } from '@atlassian/react-ufo/feature-flags-accessed';

const fg: typeof FeatureGates.checkGate = (gateName) => {
	// eslint-disable-next-line confluence-feature-gating/use-recommended-utils
	const value = FeatureGates.checkGate(gateName);
	addFeatureFlagAccessed(gateName, value);

	return value;
};

export { fg };
