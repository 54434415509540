import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { FC } from 'react';

import type {
	ContentRendererExternalQueryType,
	ContentRendererExternalQueryVariablesType,
} from '@confluence/content-body';
import { ContentRendererExternalQuery } from '@confluence/content-body';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { dangerouslyCreateSafeString } from '@confluence/safe-string';
import { ADFRenderer } from '@confluence/adf-renderer';
import {
	getContentAppearanceForAtlaskit,
	getContentAppearancePublished,
} from '@confluence/content-appearance';

export type ExternalShareContentRendererProps = {
	contentId: string | null;
	scrollTo: string;
	isMobileView: boolean;
};

export enum ContentTypeType {
	PAGE = 'page',
	BLOGPOST = 'blogpost',
}

const getADF = (serializedAdf: string) => {
	try {
		return { adf: JSON.parse(serializedAdf) };
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Invalid adf document', err);
		return { adfError: dangerouslyCreateSafeString(err.stack).toString() };
	}
};

export const ExternalShareContentRenderer: FC<ExternalShareContentRendererProps> = React.memo(
	({ contentId }) => {
		const { data, error, loading } = useQuery<
			ContentRendererExternalQueryType,
			ContentRendererExternalQueryVariablesType
		>(ContentRendererExternalQuery, {
			skip: !contentId,
			context: {
				single: true,
				contentId,
				allowOnExternalPage: true,
			},
			variables: { contentId: contentId! },
			fetchPolicy: 'cache-and-network',
		});

		if (error) {
			throw error;
		}

		if (loading || !data || !contentId) {
			return null;
		}

		const content = data.singleContent;

		if (!content) {
			return null;
		}

		const { adf } = getADF(content?.body?.atlas_doc_format?.value || '');
		const contentType = content?.type as ContentTypeType;
		const contentAppearance = getContentAppearanceForAtlaskit(
			getContentAppearancePublished(content).appearance,
		);
		const revision = content?.version?.confRev || '';

		return (
			<ErrorBoundary attribution={Attribution.EXTERNAL_COLLAB}>
				<ADFRenderer
					key={revision}
					contentId={contentId}
					document={adf}
					contentType={contentType}
					appearance={contentAppearance}
				/>
			</ErrorBoundary>
		);
	},
);
