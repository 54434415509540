import type { IntlShape } from 'react-intl-next';

import { type EditorAnalyticsAPI } from '@atlaskit/editor-common/analytics';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import type { Transaction } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';

import type {
	AnalyticsContext,
	EditorPluginAIConfigItemMarkdown,
	InitialContextSelectionEmpty,
} from '../config-items/config-items';
import type { AIManifest } from '../extensions/ai-panels/types';
import type { EditorPluginAIProvider } from '../types';

type AIPanelAction = (
	callback: (markdown: string, status: string) => void,
	intl: IntlShape,
	initialContext?: InitialContextSelectionEmpty,
	analyticsContext?: AnalyticsContext,
) => void;

type GetInitialContextAction = ({
	intl,
	configItem,
	intentSchemaId,
}: {
	intl: IntlShape;
	configItem: EditorPluginAIConfigItemMarkdown<'empty'>;
	intentSchemaId: 'summarize_intent_schema.json' | 'action_items_intent_schema.json';
}) =>
	| {
			schema?: Schema;
			providerFactory?: ProviderFactory;
			idMap: IDMap;
			initialContext?: InitialContextSelectionEmpty;
	  }
	| undefined;

export type GetInitialContextProps = {
	configItem: EditorPluginAIConfigItemMarkdown<'empty'>;
	intentSchemaId: 'summarize_intent_schema.json' | 'action_items_intent_schema.json';
	editorView?: EditorView;
	providerFactory?: ProviderFactory;
	idMap: IDMap;
	updateIdMap: ({
		idMap,
		selectionType,
	}: {
		idMap: IDMap;
		selectionType: 'range' | 'empty';
	}) => void;
	intl: IntlShape;
};

export type AIPanelDocumentProps = {
	editorView?: EditorView;
	generativeAIApiUrl: string;
	product: EditorPluginAIProvider['product'];
	callback: (markdown: string, status: string) => void;
	intl: IntlShape;
	initialContext?: InitialContextSelectionEmpty;
	analyticsContext?: AnalyticsContext;
};

export type ConvertExtensionProps = {
	api: AIManifest['api'];
	editorView?: EditorView;
	localId: string;
	extensionKey: ExtensionKeys;
	idMap: IDMap;
	tr?: Transaction;
	addToHistory?: boolean;
};

type ConvertExtensionAction = (
	api: AIManifest['api'],
	localId: string,
	extensionKey: ExtensionKeys,
	addToHistory?: boolean,
) => void;

type TriggerAIPanelFeedbackCollectorAction = (
	extensionKey: string,
	markdown: string,
	locale: IntlShape['locale'],
	fireAIAnalyticsEvent?: EditorAnalyticsAPI['fireAnalyticsEvent'],
) => void;

export type EditorPluginAIActions = {
	getInitialAIPanelContext: GetInitialContextAction;
	summariseDocument: AIPanelAction;
	findActionItems: AIPanelAction;
	convertExtension: ConvertExtensionAction;
	regenerateExtension: ConvertExtensionAction;
	triggerAIPanelFeedbackCollector: TriggerAIPanelFeedbackCollectorAction;
};

// TODO: When manifest and confluence backend is updated we need to update the extension keys
// Please make sure to do a search for all the extension keys and update them
export enum ExtensionKeys {
	AISummaryExtension = 'ai-summary-block:aiSummaryExtension',
	AISummaryBodiedExtension = 'ai-summary-block:aiSummaryBodiedExtension',
	AIActionItemsExtension = 'ai-action-items-block:aiActionItemsExtension',
	AIActionItemsBodiedExtension = 'ai-action-items-block:aiActionItemsBodiedExtension',
}
