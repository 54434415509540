import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { getOnboardingStateGQL } from './OnboardingStatus.graphql';

export async function preloadOnboardingState(key: string | string[]) {
	const { isLicensed, isLoggedIn } = await getPreloaderFnContext();
	// skip preloading is user is not logged in or is not licensed (anonymous)
	if (!isLicensed || !isLoggedIn) {
		return Promise.resolve({});
	}
	return query({
		query: getOnboardingStateGQL,
		variables: { key },
	});
}
