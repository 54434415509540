import type {
	BrowserMetricsConfig,
	GlobalEventTypeConfig,
	WebVitalsGlobalEventTypeConfig,
	GenericGlobalEventTypeConfig,
	PageSegmentLoadGlobalEventTypeConfig,
	PageLoadGlobalEventTypeConfig,
	CustomPlugin,
} from '@atlassian/browser-metrics/types';
import { BMEventsType, PageVisibleFields } from '@atlassian/browser-metrics/types';

import { getBuildInfo } from '@confluence/build-info';
import { Cookies } from '@confluence/cookies';

import {
	FF_ALLOWLIST_FOR_PERF_METRICS,
	ALLOWLIST_FFS_CUSTOM_EVENTS,
} from '../featureFlagAllowListForMetrics';
import { customEventPlugin, pageLoadEventPlugin, webVitalsEventPlugin } from '../plugins';
import { transformObjectToFlattenCustomValuesType } from '../utils/transformObjectToFlattenCustomValuesType';
import { ssrRegionPlugin } from '../plugins/ssrRegionPlugin';
import { getLighthouseMetrics } from '../plugins/lighthouse-metrics';

/**
 * Returns event configurations that add Confluence specific data.
 *
 * @param sessionData
 */
export const getCustomEventConfigs = (sessionData: any): BrowserMetricsConfig['events'] => {
	const { cloudId, environment, shard, featureFlags } = sessionData;
	const plugins: CustomPlugin[] = [];

	const uniqueIdentifier = featureFlags['confluence.frontend.bm.unique-identifier']
		? Cookies.get('bmUniqueIdentifier')
		: undefined;

	plugins.push(getLighthouseMetrics);

	/**
	 * https://product-fabric.atlassian.net/browse/CCP-1320
	 * Remove unused attributes in page-segment-load event
	 */
	const buildInfo = transformObjectToFlattenCustomValuesType({
		buildInfo: getBuildInfo(),
	});

	/**
	 * Data to be added to all events.
	 */
	const allEventConfig: GlobalEventTypeConfig = {
		custom: transformObjectToFlattenCustomValuesType({
			cloudId,
			environment,
			shard,
			ssr: Boolean(window.__SSR_RENDERED__),
			uniqueIdentifier,
		}),
		featureFlags: ALLOWLIST_FFS_CUSTOM_EVENTS,
		sfxEvents: {
			activeTab: PageVisibleFields.pageVisibleState,
		},
		plugins,
	};

	/**
	 * Data to be added to only page-load events.
	 */
	const pageLoadEventConfig: PageLoadGlobalEventTypeConfig = {
		custom: buildInfo,
		plugins: [pageLoadEventPlugin, ssrRegionPlugin],
		histogram: {
			initial: {
				fmp: '1000_2000_2500_3000_4000_5000',
				tti: '2000_2500_3000_3500_4000_5000_6000',
			},
			transition: {
				fmp: '500_1000_1500_2000_2500_3000',
				tti: '1000_1500_2000_2500_3000_4000',
			},
		},
		// page-load type event will include allEventConfig.featureFlags + the following list.
		featureFlags: FF_ALLOWLIST_FOR_PERF_METRICS,
	};

	/**
	 * Data to be added to only custom events.
	 */
	const pageSegmentLoadEventConfig: PageSegmentLoadGlobalEventTypeConfig = {
		plugins: [customEventPlugin, ssrRegionPlugin],
		histogram: {
			initial: {
				fmp: '1000_2000_2500_3000_4000_5000',
				tti: '2000_2500_3000_3500_4000_5000_6000',
			},
			transition: {
				fmp: '500_1000_1500_2000_2500_3000',
				tti: '1000_1500_2000_2500_3000_4000',
			},
		},
		// page-segment-load type event will include allEventConfig.featureFlags + the following list.
		featureFlags: FF_ALLOWLIST_FOR_PERF_METRICS,
		enableBundleEvalTimings: false,
	};

	const customEventConfig: GenericGlobalEventTypeConfig = {
		custom: buildInfo,
		plugins: [customEventPlugin],
	};

	const webVitalsConfig: WebVitalsGlobalEventTypeConfig = {
		custom: buildInfo,
		enabled: true,
		plugins: [webVitalsEventPlugin],
		// Undefined timeout defaults to 30000ms, increasing timeout here on Cypress tests to prevent flakiness.
		timeout: window?.Cypress ? undefined : 10000,
		ttciEnabled: false,
		featureFlags: FF_ALLOWLIST_FOR_PERF_METRICS,
	};

	return {
		all: allEventConfig,
		[BMEventsType.PAGE_LOAD]: pageLoadEventConfig,
		[BMEventsType.PAGE_SEGMENT_LOAD]: pageSegmentLoadEventConfig,
		[BMEventsType.CUSTOM]: customEventConfig,
		[BMEventsType.WEB_VITALS]: webVitalsConfig,
	};
};
