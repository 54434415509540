import React, { createContext, useContext, useRef } from 'react';

import { useSyncExternalStore } from 'use-sync-external-store/shim';

import { useStableObject } from '../../util/use-stable-object';

import type {
	PostOfficeRouteConfig,
	PostOfficeRouteProviderProps,
	PostOfficeRouteStore,
	RouteChangeParams,
	RouteState,
} from './types';

// Store

export const createPostOfficeRouteStore = ({
	routeSubscriber,
	initialUrl,
}: PostOfficeRouteConfig): PostOfficeRouteStore => {
	let state = initializeState({ initialUrl });

	const handleUpdate = (routeChange: RouteChangeParams) => {
		const next = routeChange.next?.toString();

		if (next === state.current) {
			return;
		}

		state = {
			previous: state.current,
			current: next,
		};
	};

	const subscribe = (next: () => void) =>
		routeSubscriber((routeChange) => {
			handleUpdate(routeChange);
			next();
		});

	const getSnapshot = () => {
		return state;
	};

	return {
		subscribe,
		getSnapshot,
	};
};

const usePostOfficeRouteStore = ({ subscribe, getSnapshot }: PostOfficeRouteStore) => {
	const route = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

	return useStableObject(route);
};

const initialState: RouteState = {
	previous: undefined,
	current: undefined,
};

const initializeState = ({
	initialUrl,
}: Pick<PostOfficeRouteConfig, 'initialUrl'>): RouteState => ({
	...initialState,
	current: initialUrl ? initialUrl?.toString() : undefined,
});

// Context

export const PostOfficeRouteContext = createContext<RouteState>(initialState);

export const PostOfficeRouteProvider = ({
	children,
	...props
}: PostOfficeRouteProviderProps): JSX.Element => {
	const ref = useRef<PostOfficeRouteStore>();

	if (!ref.current) {
		ref.current = createPostOfficeRouteStore(props);
	}

	const route = usePostOfficeRouteStore(ref.current);

	return (
		<PostOfficeRouteContext.Provider value={route}>{children}</PostOfficeRouteContext.Provider>
	);
};

export const usePostOfficeRoute = (): RouteState => useContext(PostOfficeRouteContext);

//Route listener
