// "confluence.fe.custom.XXX"
export const MENTIONS_QUERY_CUSTOM_KEY = 'mentions-query';
export const PROFILE_CARD_QUERY_CUSTOM_KEY = 'profile-card-query';
export const SPACE_NAVIGATION_QUERY_CUSTOM_KEY = 'space-navigation-query';
export const LEGACY_EXTENSION_LOADING_CUSTOM_KEY = 'legacy-extension-loading';
export const FABRIC_PUBLISH_PAGE_KEY = 'fabric-publish-page';
export const TIME_TO_APP_IDLE_KEY = 'time-to-app-idle';
export const SHARE_FROM_RESTRICTIONS_KEY = 'share-notif-from-restrictions';

export const TEAM_CALENDARS_EVENT_FETCH_KEY = 'tc-events-fetch';

export const TEAM_CALENDARS_SUBCALS_FETCH_KEY = 'tc-subcals-fetch';
export const AI_SNIPPET_HOVER_CARD_SEGMENT_KEY = 'ai-snippet-hover-card';
