import { query } from '@confluence/query-preloader-tools';
import { preloadFeed } from '@confluence/feed/entry-points/preloadFeed';

import { preloadSidePanel } from '../../preloadSidePanel';

import { getRecentWorkQueryOptions } from './recentWorkQueryHelpers';
import { ALL_RECENT_ITEMS_COUNT_HOME_REFRESH } from './recentListConstants';

const preloadRecentWork = (args: {
	cloudId: string;
	isLoggedIn: boolean;
	isSpaceAliasFFEnabled?: boolean;
	isWhiteboardViewedEnabled?: boolean;
	isDatabaseEnabled?: boolean;
	first?: number;
	isSmartLinkEmbedEnabled?: boolean;
}) => {
	const queryOptions = getRecentWorkQueryOptions(args);

	if (queryOptions.skip) {
		return Promise.resolve({});
	}

	return query(queryOptions);
};

export const preloadOverview = ({
	userId,
	cloudId,
	isLicensed,
	isSpaceAliasFFEnabled = false,
	isMyVisitedSpacesEnabled = false,
	isWhiteboardViewedEnabled = false,
	isDatabaseEnabled = false,
	isSmartLinkEmbedEnabled = false,
}: {
	userId: string | null;
	cloudId: string;
	isLicensed: boolean;
	isSpaceAliasFFEnabled?: boolean;
	isMyVisitedSpacesEnabled?: boolean;
	isWhiteboardViewedEnabled?: boolean;
	isDatabaseEnabled?: boolean;
	isSmartLinkEmbedEnabled?: boolean;
}) => {
	return Promise.all([
		preloadRecentWork({
			cloudId,
			isLoggedIn: !!userId,
			isSpaceAliasFFEnabled,
			isWhiteboardViewedEnabled,
			isDatabaseEnabled,
			first: ALL_RECENT_ITEMS_COUNT_HOME_REFRESH,
			isSmartLinkEmbedEnabled,
		}),
		preloadFeed({
			isLoggedIn: !!userId,
			isSpaceAliasFFEnabled,
		}),
		preloadSidePanel({
			isSpaceAliasFFEnabled,
			isLicensed,
			isMyVisitedSpacesEnabled,
		}),
	]);
};
