import { getPreloaderFnContext, query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';
import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';
import { preloadBlogsToggledOffChangeboarding } from '@confluence/blog-tree/entry-points/preloadBlogsToggledOffChangeboarding';
import { preloadUseExtensionList } from '@confluence/forge-ui/entry-points/preloadUseExtensionList';
import { FORGE_MODULE_SPACE_PAGE } from '@confluence/forge-ui/entry-points/ForgeModuleType';

import { SpaceNavigationQuery } from './SpaceNavigation/SpaceNavigationQuery.graphql';

export async function preloadSideNavigationNeedsContentId(spaceKey: string, contentId: string) {
	const isSSRForgeAppEnabled = fg('ssr_forge_apps_in_side_navigation');
	if (isSSRForgeAppEnabled) {
		return preloadUseExtensionList({
			contentId,
			spaceKey,
			moduleType: FORGE_MODULE_SPACE_PAGE,
		});
	}
}

export async function preloadSideNavigation(spaceKey: string) {
	const { isLicensed } = await getPreloaderFnContext();

	const isBlogTreeUnderContentTreeEnabled = fg('blog_tree_under_content_tree');
	const isBlogsToggledOffChangeboardingEnabled = fg('blogs_toggled_off_changeboarding');

	const tasks: Array<Promise<any>> = [
		query({
			query: SpaceNavigationQuery,
			variables: {
				spaceKey,
				isLicensed,
			},
		}),
	];

	if (isBlogTreeUnderContentTreeEnabled) {
		tasks.push(preloadBlogTree({ spaceKey }));
	}

	if (isBlogsToggledOffChangeboardingEnabled) {
		tasks.push(preloadBlogsToggledOffChangeboarding());
	}

	return Promise.all(tasks);
}
