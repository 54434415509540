import { preloadSpacePages } from '@confluence/space-pages/entry-points/preloadSpacePages';
import { getIsFolderEnabled } from '@confluence/folder-utils/entry-points/useIsFolderEnabled';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export const preloadSpacePagesRoute = async (match: RouteMatch) => {
	const { featureFlags, userId, isLicensed, isLoggedIn } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey } = match.params;

	tasks.push(
		preloadSpacePages({
			spaceKey,
			queryParams: match.query,
			userId,
			isLicensed,
			isLoggedIn,
			spaceId: '',
			isWhiteboardEnabled: Boolean(featureFlags['confluence.frontend.whiteboard.enable']),
			isDatabaseEnabled: Boolean(featureFlags['confluence.frontend.databases.enabled']),
			isSmartLinkEmbedEnabled: Boolean(featureFlags['confluence.frontend.embeds.enabled']),
			isFolderEnabled: getIsFolderEnabled(),
		}),
	);

	return Promise.all(tasks);
};
