import {
	type LastInteractionFinishInfo,
	type PostInteractionLogOutput,
	type ReactProfilerTiming,
} from '../common/common/types';
import type { LabelStack } from '../interaction-context';

const POST_INTERACTION_LOG_SEND_TIMEOUT = 3000;

export default class PostInteractionLog {
	/**
	 * Basic info about interaction that has just finished
	 */
	lastInteractionFinish: LastInteractionFinishInfo | null = null;

	/**
	 * Array of observed react render timings
	 */
	reactProfilerTimings: ReactProfilerTiming[] = [];

	/**
	 * Store the scheduled sink timeout Id so that it can be cancelled when needed
	 */
	sinkTimeoutId: number | null = null;

	/**
	 * Handler function to process / send the observation data
	 */
	sinkHandlerFn: (output: PostInteractionLogOutput) => void | Promise<void> = () => {};

	/**
	 * Set the fn that would be invoked to process / send the observation data
	 */
	sinkHandler(sinkHandlerFn: (output: PostInteractionLogOutput) => void | Promise<void>) {
		this.sinkHandlerFn = sinkHandlerFn;
	}

	/**
	 * Reset state of the log
	 */
	reset() {
		this.lastInteractionFinish = null;
		this.reactProfilerTimings = [];

		if (this.sinkTimeoutId != null) {
			clearTimeout(this.sinkTimeoutId);
			this.sinkTimeoutId = null;
		}
	}

	/**
	 * Check if there is data in the log
	 */
	hasData() {
		return this.reactProfilerTimings?.length > 0;
	}

	/**
	 * Send the log if there is data
	 */
	sendPostInteractionLog() {
		if (!this.hasData() || !this.lastInteractionFinish || !this.sinkHandlerFn) {
			this.reset();
			return;
		}

		this.sinkHandlerFn({
			lastInteractionFinish: this.lastInteractionFinish,
			reactProfilerTimings: this.reactProfilerTimings,
		});

		this.reset();
	}

	/**
	 * This fn should be invoked when an intraction has finished
	 * Basic details about the finished interaction will be recorded
	 * A timeout will be setup to send the post interaction observation after some time.
	 */
	onInteractionComplete(name: string, start: number, end: number) {
		this.lastInteractionFinish = {
			name,
			start,
			end,
		};

		this.sinkTimeoutId = window.setTimeout(() => {
			this.sendPostInteractionLog();
		}, POST_INTERACTION_LOG_SEND_TIMEOUT);
	}

	/**
	 * This fn should be invoked when a React render happens after interaction has finished
	 */
	addProfilerTimings(
		labelStack: LabelStack,
		type: 'mount' | 'update' | 'nested-update',
		actualDuration: number,
		baseDuration: number,
		startTime: number,
		commitTime: number,
	) {
		if (this.lastInteractionFinish != null) {
			this.reactProfilerTimings.push({
				type,
				actualDuration,
				baseDuration,
				startTime,
				commitTime,
				labelStack,
			});
		}
	}
}
