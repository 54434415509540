import { useContext, useEffect } from 'react';

import { useMutation as useApolloMutation } from '@apollo/react-hooks';

import UFOInteractionContext from '@atlassian/react-ufo/interaction-context';

import getOperationName from '../utils/get-operation-name';

export const useMutation: typeof useApolloMutation = (...args) => {
	const ufoContext = useContext(UFOInteractionContext);

	const apolloResult = useApolloMutation(...args);

	const [query] = args;
	const operationName = getOperationName(query);
	const isLoading = apolloResult?.[1]?.loading;

	useEffect(() => {
		if (isLoading) {
			return ufoContext?.hold(operationName);
		}
	}, [isLoading, operationName, ufoContext]);

	return apolloResult;
};
