import React from 'react';

import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { usePageContentId } from '@confluence/page-context';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';
import type { RouteMatch } from '@confluence/route';

import { navdexEnd } from '../naxdev';
import { EditPageOrBlogV2Route } from './EditPageOrBlogV2Route';

export const CompanyHubEditRoute = (routeProps: RouteMatch) => {
	const spaceKey = getCompanyHubSpaceKey() ?? '';
	const [contentId] = usePageContentId();

	if (!contentId) {
		return <FullPageLoadingScreen />;
	}

	return (
		<EditPageOrBlogV2Route {...routeProps} params={{ contentId, spaceKey, contentType: 'pages' }} />
	);
};

CompanyHubEditRoute.NAVIGATION_PARAMS = () => {
	const spaceKey = getCompanyHubSpaceKey() ?? '';

	return {
		Screen: {
			screenEvent: {
				name: 'companyHubEditScreen',
				id: spaceKey,
				...navdexEnd,
			},
			// When no contentId is passed, Screen.js handles querying it and updating pageState with it.
			pageState: { spaceKey },
		},
		MainLayout: {
			navView: PRODUCT_HOME_ID,
		},
	};
};
