import React from 'react';

import { BaseMeasures } from './BaseMeasures';

export enum WATERFALL_INITIAL_MEASURES {
	REDIRECTION = 'wf/navigation/redirection',
	DNS = 'wf/navigation/dns',
	TCP = 'wf/navigation/tcp',
	TLS = 'wf/navigation/tls',
	RESPONSE = 'wf/navigation/response',
	TTFB = 'wf/navigation/ttfb',
	FULL_HTML = 'wf/navigation/fullHtml',
	CSS_MAIN = 'wf/css/main',
	CSS_SUPERBATCH = 'wf/css/superbatch',
	CSS_COMPILED = 'wf/css/compiled',
	HTML_THEME_INIT = 'wf/html/themeInit',
	HTML_REACT_ROOT = 'wf/html/reactRoot',
	HTML_SSR_SCRIPTS = 'wf/html/ssrScripts',
	HTML_TOP_NAV = 'wf/html/topNav',
	HTML_SIDE_NAV = 'wf/html/sideNav',
	HTML_MAIN = 'wf/html/main',
	BOOTSTRAP_SESSION_DATA = 'wf/bootstrap/getSessionData',
	BOOTSTRAP_TRANSLATION = 'wf/bootstrap/loadTranslation',
	BOOTSTRAP_LOADABLE = 'wf/bootstrap/preloadLoadable',
}

export function perfMark(name: string) {
	const nonceValue = window.__SSR_NONCE_VALUE__;
	return (
		<script
			{...(nonceValue !== undefined ? { nonce: nonceValue } : {})}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: `window["__SSR_RENDERED__"]&&window.performance.mark("${name}");`,
			}}
		/>
	);
	return null;
}

class WaterfallMeasure extends BaseMeasures {
	private markedKeys = new Set<string>();

	override markMeasureStart(name: string, timestamp?: number) {
		this.markedKeys.add(name);
		window.performance.clearMarks(`${name}.start`);
		window.performance.mark(`${name}.start`, {
			startTime: Math.floor(timestamp ?? performance.now()),
		});
	}

	override markMeasureEnd(name: string, timestamp?: number) {
		window.performance.clearMarks(`${name}.end`);
		window.performance.mark(`${name}.end`, {
			startTime: Math.floor(timestamp ?? performance.now()),
		});
	}

	getMeasureKeys(after: number): string[] {
		return Array.from(this.markedKeys).filter((key) => {
			const mark = performance.getEntriesByName(`${key}.start`, 'mark');
			return mark.length && mark[0].startTime >= after;
		});
	}

	override clearMeasures(): void {
		super.clearMeasures();
		this.markedKeys.clear();
	}
}

export const WaterfallMeasures = new WaterfallMeasure();
