import FeatureGates from '@atlaskit/feature-gate-js-client';

import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import { preloadUserPreferencesForSSR } from '@confluence/storage-manager/entry-points/preloadUserPreferencesForSSR';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadEditorData } from '@confluence/load-edit-page/entry-points/preloadEditorData';
import { preloadEditorOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadEditorOnboardingState';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { preloadDraft } from '@confluence/fabric-providers/entry-points/preloadDraft';
import { preloadBreadcrumbs } from '@confluence/breadcrumbs/entry-points/preloadBreadcrumbs';
import { preloadDataClassificationEnabledQuery } from '@confluence/data-classification/entry-points/queries';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';

import { matchEditBlog } from './matchRoutes';

export const preloadEditPageRoute = async (
	match: RouteMatch,
	_url: string,
	isTransition: boolean = false,
) => {
	const { featureFlags, userId, isLicensed, cloudId } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { contentId, spaceKey } = match.params;

	const isCustomSitesPageTitleFFOn = Boolean(featureFlags[CUSTOM_SITES_PAGE_TITLE_FF]);
	const isSpaceAliasFFEnabled = Boolean(featureFlags['confluence.frontend.space.alias']);
	const isEditorActionBarSSREnabled = Boolean(
		featureFlags['confluence.frontend.ssr-editor-actions-bar'],
	);
	const isEditorSSREnabled = Boolean(featureFlags['confluence.frontend.editor.ssr']);
	const shouldLoadDraftFromGql = Boolean(
		featureFlags['confluence.frontend.load-document-draft-from-graphql'],
	);
	const isBlog = Boolean(matchEditBlog(_url));

	const shouldPreloadMentionsProvider = Boolean(
		process.env.REACT_SSR && featureFlags['confluence.frontend.editor.ssr'],
	);

	const shouldPreloadMentions = FeatureGates.checkGate(
		'confluence_frontend_editor_preload_mentions',
	);

	tasks.push(
		preloadBreadcrumbs(spaceKey, contentId, false, isSpaceAliasFFEnabled),
		preloadEditorData({
			userId,
			contentId,
			cloudId,
			preloadNativeCollabData: true,
			shouldPreloadMentions,
			shouldPreloadMentionsProvider,
			shouldPreloadNativeEditorData: false,
			isCustomSitesPageTitleFFOn,
			shouldLoadDraftFromGql,
			isBlog,
			isTransition,
		}),
	);

	if (isLicensed) {
		tasks.push(preloadEditorOnboardingState());
	}

	if (isTransition) {
		return Promise.all(tasks);
	}

	if (process.env.REACT_SSR) {
		tasks.push(
			preloadSideNavigation(spaceKey),
			preloadSideNavigationNeedsContentId(spaceKey, contentId),
			preloadSpaceGuestList(spaceKey),
			preloadUserPreferencesForSSR({
				isLicensed,
				spaceKey,
			}),
			preloadShortcutsSmartLinks(spaceKey),
			preloadPageTree({
				spaceKey,
				statuses: PAGE_TREE_STATUSES,
				isSuperAdmin: false,
				contentId,
			}),
		);

		if (isEditorSSREnabled) {
			tasks.push(preloadMediaToken(contentId));
		}

		if (isEditorActionBarSSREnabled) {
			tasks.push(preloadDataClassificationEnabledQuery());
		}
	} else {
		tasks.push(preloadDraft(contentId, cloudId));
	}

	const shouldPreloadSpaceDetails = featureFlags['confluence.frontend.ecosystem.access.narrrowing'];
	if (shouldPreloadSpaceDetails) {
		tasks.push(preloadSpaceDetail(spaceKey, isSpaceAliasFFEnabled));
	}

	return Promise.all(tasks);
};
