import { Attribution } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';
import { SSRMeasures } from '@confluence/action-measures';
import { cfetch } from '@confluence/network';
import { constructCompatibleRequestInit } from '@confluence/network/entry-points/constructCompatibleRequestInit';

import { StatsigBootstrapQueryRaw } from './StatsigBootstrapQueryRaw';

type StatsigBootstrapData = {
	clientSdkKey: string;
	experimentValues: any;
};

type StatsigBootstrapDataPromise = Promise<StatsigBootstrapData | undefined>;

declare global {
	interface Window {
		__STATSIG_BOOTSTRAP__?: StatsigBootstrapData | StatsigBootstrapDataPromise;
	}
}

export const getStatsigBootstrap = async () => {
	// if we already have the promise or data return it
	if (window.__STATSIG_BOOTSTRAP__) {
		return window.__STATSIG_BOOTSTRAP__;
	}

	const URI = window['GLOBAL_APOLLO_CLIENT_URI'] || '/cgraphql';
	window.__STATSIG_BOOTSTRAP__ = SSRMeasures.run(
		'ssr-app/prepare/preloadQuery/fetch:StatsigBootstrapQueryRaw',
		() =>
			cfetch(
				`${URI}?q=StatsigBootstrapQueryRaw`,
				constructCompatibleRequestInit({
					method: 'POST',
					credentials: 'include',
					referrerPolicy: 'same-origin',
					headers: {
						'X-APOLLO-OPERATION-NAME': 'StatsigBootstrapQueryRaw',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						operationName: 'StatsigBootstrapQueryRaw',
						query: StatsigBootstrapQueryRaw,
					}),
				}),
			)
				.then(async (res) => {
					if (!res.ok) {
						throw new Error(`Cannot load Statsig experiment values: ${res.statusText}`);
					}

					window.__STATSIG_BOOTSTRAP__ = JSON.parse(
						(await res.json()).data.frontendExperimentsBootstrap?.value || '{}',
					);

					return window.__STATSIG_BOOTSTRAP__;
				})
				.catch((e) => {
					getMonitoringClient().submitError(e, {
						attribution: Attribution.STATSIG,
					});

					return undefined;
				}),
	);

	return window.__STATSIG_BOOTSTRAP__;
};
