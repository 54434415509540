import type FeatureFlagClient from '@atlaskit/feature-flag-client';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { fg } from '@confluence/feature-gating';
import { useBooleanFeatureFlag } from '@confluence/session-data';

// LD FF: https://app.launchdarkly.com/confluence/production/features/confluence.frontend.auto.reload.page.comments/targeting
// Statsig: https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/experiments/auto-reload_page_comments/setup
export const PAGE_COMMENTS_AUTO_RELOAD_FF = 'confluence.frontend.auto.reload.page.comments';

const PAGE_COMMENTS_AUTO_RELOAD_EXPERIMENT = 'auto-reload_page_comments';
const EXPERIMENT_CONTROL = 'control';
const EXPERIMENT_VARIATION = 'variation';

type ValidCohort = 'control' | 'variation';

type PageCommentsSortingCohorts =
	| 'control'
	| 'experimentOldestButtonTop'
	| 'experimentNewestButtonTop';

export const useIsPageCommentsAutoReloadEnabled = () => {
	const isPageCommentsAutoReloadFFEnabled = useBooleanFeatureFlag(PAGE_COMMENTS_AUTO_RELOAD_FF);

	if (isPageCommentsAutoReloadFFEnabled) {
		return (
			FeatureGates.getExperimentValue<ValidCohort>(
				PAGE_COMMENTS_AUTO_RELOAD_EXPERIMENT,
				'cohort',
				EXPERIMENT_CONTROL,
			) === EXPERIMENT_VARIATION
		);
	}

	return false;
};

export const isPageCommentsAutoReloadEnabled = (featureFlagClient: FeatureFlagClient) => {
	const isPageCommentsAutoReloadFFEnabled = featureFlagClient.getBooleanValue(
		PAGE_COMMENTS_AUTO_RELOAD_FF,
		{ default: false, shouldTrackExposureEvent: true },
	);

	if (isPageCommentsAutoReloadFFEnabled) {
		return (
			FeatureGates.getExperimentValue<ValidCohort>(
				PAGE_COMMENTS_AUTO_RELOAD_EXPERIMENT,
				'cohort',
				EXPERIMENT_CONTROL,
			) === EXPERIMENT_VARIATION
		);
	}

	return false;
};

export const usePageCommentsSortingExperiment = () => {
	const pageCommentsSortingExperimentCohort =
		FeatureGates.getExperimentValue<PageCommentsSortingCohorts>(
			'confluence_page_comments_sort_by_date',
			'cohort',
			'control',
		);
	const isPageCommentsSortingExperimentEnabled =
		(pageCommentsSortingExperimentCohort === 'experimentOldestButtonTop' ||
			pageCommentsSortingExperimentCohort === 'experimentNewestButtonTop') &&
		fg('confluence_page_comments_sort_by_date_feature_gate');
	return {
		isPageCommentsSortingExperimentEnabled,
		pageCommentsSortingExperimentCohort,
	};
};
