import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { GetFeatureCodesResponse } from '.';
import {
	CONFLUENCE_INSIGHTS_FEATURE_CODES_FEATURE_GATE,
	FEATURE_CODES_QUERY_PARAM,
	FeatureCode,
} from '.';

/**
 * Generate a new href with the given feature codes query parameter
 *
 * @param href The current href
 * @param featureAttribution The feature codes to add
 * @returns The new href with the feature codes query parameter
 */
export const generatePathWithFeatureCodesQueryParam = (
	href: string,
	featureCodes: FeatureCode[],
): string => {
	if (!featureCodes.length) {
		return href;
	}

	const [path, queryString] = href.split('?');
	const searchParams = new URLSearchParams(queryString);

	// If the query param already exists, append the new feature codes to the existing ones
	if (searchParams.has(FEATURE_CODES_QUERY_PARAM)) {
		const existingFeatureCodes = searchParams.get(FEATURE_CODES_QUERY_PARAM) || '';
		const newFeatureCodes = [...new Set([...existingFeatureCodes.split(','), ...featureCodes])];
		searchParams.set(FEATURE_CODES_QUERY_PARAM, newFeatureCodes.join(','));
	} else {
		searchParams.append(FEATURE_CODES_QUERY_PARAM, featureCodes.join(','));
	}

	const newQueryString = searchParams.toString();

	return newQueryString ? `${path}?${newQueryString}` : path;
};

/**
 * A hook to get the feature code from the URL query parameters.
 * @returns The feature code from the URL query parameters.
 */
export const getFeatureCodes = (queryParams: URLSearchParams): GetFeatureCodesResponse => {
	const isInsightsFeatureAttributionEnabled = FeatureGates.checkGate(
		CONFLUENCE_INSIGHTS_FEATURE_CODES_FEATURE_GATE,
	);

	if (!isInsightsFeatureAttributionEnabled) {
		return { from: undefined };
	}

	const featureCodes = queryParams.get(FEATURE_CODES_QUERY_PARAM)?.split(',');

	const fromCode = featureCodes?.find((code) =>
		Object.values(FeatureCode).includes(code as FeatureCode),
	);

	return { from: fromCode as FeatureCode | undefined };
};

/**
 * Removes the feature code from the URL query parameters.
 *
 * @param queryParams The URL query parameters to remove the feature code from.
 */
export const hideFeatureCodesQueryParam = (queryParams: URLSearchParams): void => {
	queryParams.delete(FEATURE_CODES_QUERY_PARAM);
	const finalQueryParams = queryParams.toString() === '' ? '' : `?${queryParams.toString()}`;
	if (typeof window !== 'undefined') {
		window.history.replaceState(
			{},
			'',
			`${document.location.origin}${window.location.pathname}${finalQueryParams}`,
		);
	}
};

/**
 * Checks if the provided feature codes contain the specified feature code.
 *
 * @param existingFeatureCodes The feature codes to check.
 * @param featureCodeToCheck The feature code to check for.
 * @returns True if the feature codes contain the feature code, false otherwise.
 */
export const hasFeatureCode = (
	existingFeatureCodes: FeatureCode[],
	featureCodeToCheck: FeatureCode,
): boolean => {
	return existingFeatureCodes.includes(featureCodeToCheck);
};

/**
 * Adds a feature code to the provided feature codes.
 *
 * @param existingFeatureCodes The feature codes to add the feature code to.
 * @param featureCodeToAdd The feature code to add.
 * @returns The feature codes with the feature code added.
 */
export const addFeatureCode = (
	existingFeatureCodes: FeatureCode[],
	featureCodeToAdd: FeatureCode,
): FeatureCode[] => {
	if (hasFeatureCode(existingFeatureCodes, featureCodeToAdd)) {
		return existingFeatureCodes;
	}

	return [...existingFeatureCodes, featureCodeToAdd];
};

/**
 * Removes a feature code from the provided feature codes.
 *
 * @param existingFeatureCodes The feature codes to remove the feature code from.
 * @param featureCodeToRemove The feature code to remove.
 * @returns The feature codes with the feature code removed.
 */
export const removeFeatureCode = (
	existingFeatureCodes: FeatureCode[],
	featureCodeToRemove: FeatureCode,
): FeatureCode[] => {
	if (!hasFeatureCode(existingFeatureCodes, featureCodeToRemove)) {
		return existingFeatureCodes;
	}

	return existingFeatureCodes.filter((code: string) => code !== featureCodeToRemove);
};
