import { getLogger } from '@confluence/logger';
import { getMonitoringClient, setLoadableErrorAttributes } from '@confluence/monitoring';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';

import { defer } from './defer';
import type { ConditionValue } from './types';
import type { MustPreloadLoadableNames } from './MustPreloadLoadableNames';

const logger = getLogger('Loadable');

const LOADING_TIMEOUT_FF = 'confluence.ssr.loadable.loading.timeout';

let pending: Promise<any>[] = [];
let resolveMap: { [nameOrId: string]: Function } = {};
let loadableSet = new Set<string>();
let preloadOnlyLoadableSet = new Set<string>();
let conditionalLoadableSet = new Set<ConditionValue>();

function initializeLoadable() {
	if (pending.length === 0) {
		const LOADING_TIMEOUT =
			process.env.NODE_ENV === 'production'
				? Number(getSSRFeatureFlag(LOADING_TIMEOUT_FF)) || 5000
				: 30000;
		for (const nameOrId of window['__LOADABLE__'] || []) {
			const { resolve, promise } = defer(LOADING_TIMEOUT, () => {
				logger.error`__LOADABLE__ timed out: ${nameOrId}`;
				(window.__LOADABLE_TIMEOUTS__ = window?.__LOADABLE_TIMEOUTS__ || []).push(nameOrId);
			});
			pending.push(promise);
			resolveMap[nameOrId] = resolve;
		}
	}
}

export function preloadLoadable(): Promise<any> {
	initializeLoadable();
	return Promise.all(pending).then(() => {
		if (window.__LOADABLE_TIMEOUTS__?.length) {
			const error = new Error(`Loadable initialization timeout`);
			const loadableIds = window.__LOADABLE_TIMEOUTS__;
			setLoadableErrorAttributes(error, { loadableIds });

			// NOTE: apparently there's plenty of timeout errors happen during the normal flow of operation.
			// While we need to troubleshoot and fix them eventually, we don't want to flood the monitoring,
			// but we also want to track and alert upon them. Hence using a separate attribution.
			if (navigator.onLine) {
				getMonitoringClient().submitError(error, { attribution: 'backbone-loadable' });
			}
		}
	});
}

export function preloadIfNeeded(
	id: string | undefined,
	name: string | undefined,
	preload: () => Promise<any>,
) {
	initializeLoadable();
	if ((id && id in resolveMap) || (name && name in resolveMap)) {
		const resolve = () => {
			// Note that due to the _mustPreloadWhenRenderingSPA logic the loadable may appear
			// in the list of loadables both under id and under name. If we have 2 entries for
			// the loadable, we should resolve both.
			if (id && resolveMap[id]) resolveMap[id]();
			if (name && resolveMap[name]) resolveMap[name]();
		};
		logger.debug`SSR Preload: ${name || id}`;
		preload().then(resolve, (e) => {
			logger.error(e);
			resolve();
		});
	}
}

export function addLoadableId(id?: string | ConditionValue, options?: { preloadOnly: boolean }) {
	if (!id) return;

	if (typeof id === 'string') {
		preloadOnlyLoadableSet.add(id);

		if (!options?.preloadOnly) {
			loadableSet.add(id);
		}
	} else {
		conditionalLoadableSet.add(id);
	}
}

export function getLoadableIds(): {
	loadableIds: string[];
	preloadOnlyLoadableIds: string[];
	conditionalLoadableIds: ConditionValue[];
} {
	return {
		loadableIds: Array.from(loadableSet),
		preloadOnlyLoadableIds: Array.from(preloadOnlyLoadableSet),
		conditionalLoadableIds: Array.from(conditionalLoadableSet),
	};
}

/**
 * Allow components declare loose dependencies on other loadable components that
 * would tell Loadable infrastructure to preload these components. It means that
 * component A can declare that it needs component B preloaded, when component A
 * is rendered on the server.
 *
 * This is functional way to declare dependency between code that runs on SSR and
 * code that needs to be preloaded in the browser, similar to Loadable option
 * `_mustPreloadWhenRenderingSPA`.
 *
 * NOTE: this approach is needed, because we cannot have direct dependencies from
 * next to classic code so it serves as a "clean" hack allowing gradual migration
 * to next.
 *
 * @param id Loadable id / name
 */
export function mustPreloadWhenRenderingSPA(loadableIds: Array<string | MustPreloadLoadableNames>) {
	if (process.env.REACT_SSR) {
		loadableIds.forEach((id) => addLoadableId(id));
	}
}

export function _resetOnlyForTest() {
	pending = [];
	resolveMap = {};
	loadableSet = new Set();
	preloadOnlyLoadableSet = new Set();
	conditionalLoadableSet = new Set();
}
