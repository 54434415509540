import FeatureGates from '@atlaskit/feature-gate-js-client';

import { HomeRecentActivitiesQuery, HomeRecentCreatedQuery } from './HomeRecentQueries.graphql';
import { ActivityEventType, ActivitiesObjectType } from './__types__/HomeRecentActivitiesQuery';

// This list matches the event types Activity Platform uses for
// activities.myActivities.workedOn with CREATED and UPDATED
// removed as those events are only used in Jira
// https://bitbucket.org/atlassian/worklens/src/bff1a264119c0121e1e7ec7787442cdf0e177f40/src/main/java/com/atlassian/worklens/settings/EventsSettings.java#lines-38
const WORKED_ON_EVENT_TYPES = [
	ActivityEventType.COMMENTED,
	ActivityEventType.EDITED,
	ActivityEventType.PUBLISHED,
];

const OBJECT_TYPES = [ActivitiesObjectType.PAGE, ActivitiesObjectType.BLOGPOST];

const PAGE_SIZE = 24;

export const getRecentQueryOptions = ({
	pill,
	cloudId,
	endCursor,
	isSpaceAliasFFEnabled,
	isWhiteboardViewedEnabled,
	isWhiteboardUpdatedEnabled,
	isDatabaseEnabled,
	isSmartLinkEmbedEnabled,
}: {
	pill: string | undefined;
	cloudId: string;
	endCursor: string;
	isSpaceAliasFFEnabled: boolean;
	isWhiteboardViewedEnabled?: boolean;
	isWhiteboardUpdatedEnabled?: boolean;
	isDatabaseEnabled?: boolean;
	isSmartLinkEmbedEnabled?: boolean;
}) => {
	const showOwnedByMeText = FeatureGates.getExperimentValue<boolean>(
		'confluence_frontend_page_ownership_recent_tab_page',
		'isEnabled',
		false,
	);

	const userField = showOwnedByMeText ? 'owner' : 'creator';

	switch (pill) {
		case 'created':
			return {
				query: HomeRecentCreatedQuery,
				variables: {
					cql: `type in (page, blogpost${
						isWhiteboardUpdatedEnabled ? ', whiteboard' : ''
					}${isDatabaseEnabled ? ', database' : ''}${
						isSmartLinkEmbedEnabled ? ', embed' : ''
					}) and ${userField}=currentUser() order by created desc`,
					cqlcontext: `{"contentStatuses":["draft", "current"]}`,
					first: PAGE_SIZE,
					after: endCursor,
					includeAlias: isSpaceAliasFFEnabled,
				},
			};
		case 'worked-on':
			return {
				query: HomeRecentActivitiesQuery,
				variables: {
					cloudId,
					first: PAGE_SIZE,
					after: endCursor,
					eventTypes: WORKED_ON_EVENT_TYPES,
					objectTypes: [
						...OBJECT_TYPES,
						...(isWhiteboardUpdatedEnabled ? [ActivitiesObjectType.WHITEBOARD] : []),
						...(isDatabaseEnabled ? [ActivitiesObjectType.DATABASE] : []),
						...(isSmartLinkEmbedEnabled ? [ActivitiesObjectType.EMBED] : []),
					],
					includeAlias: isSpaceAliasFFEnabled,
				},
			};
		case 'all':
		default:
			return {
				query: HomeRecentActivitiesQuery,
				variables: {
					cloudId,
					first: PAGE_SIZE,
					after: endCursor,
					eventTypes: [],
					objectTypes: [
						...OBJECT_TYPES,
						...(isWhiteboardViewedEnabled ? [ActivitiesObjectType.WHITEBOARD] : []),
						...(isDatabaseEnabled ? [ActivitiesObjectType.DATABASE] : []),
						...(isSmartLinkEmbedEnabled ? [ActivitiesObjectType.EMBED] : []),
					],
					includeAlias: isSpaceAliasFFEnabled,
				},
			};
	}
};
