import { pluginFactory } from '@atlaskit/editor-common/utils';
import type { EditorState, ReadonlyTransaction } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import { aiSpellingGrammarPluginKey } from './ai-spelling-grammar-plugin-key';
import { DOC_CHANGE_COUNT_LIMIT } from './constants';
import { regenerateAllDecorations, regenerateSelectedSpellingDecorations } from './decorations';
import { type DocumentSGChecker } from './document-sg-checker';
import { reducer } from './reducer';
import type { AISpellingGrammarPluginState } from './states';
import {
	canSplitParagraphIntoSentences,
	findProactiveAIBlockToUpdate,
	getAllProactiveAIBlocks,
} from './utils';
const handleDocChanged = (
	tr: ReadonlyTransaction,
	pluginState: AISpellingGrammarPluginState,
	newEditorState: EditorState,
): AISpellingGrammarPluginState => {
	const { updatedProactiveAIBlock } = findProactiveAIBlockToUpdate(tr, pluginState, newEditorState);

	const { decorationSet, selectedID, docChangeCountUntilLimit } = pluginState;

	const uniqueInteractionID =
		tr.getMeta(aiSpellingGrammarPluginKey)?.uniqueInteractionID ?? selectedID;

	return {
		...pluginState,
		selectedID: uniqueInteractionID,
		decorationSet: regenerateAllDecorations({
			decorationSet,
			tr,
			pluginState: {
				...pluginState,
				proactiveAIBlocks: updatedProactiveAIBlock,
			},
		}),
		proactiveAIBlocks: updatedProactiveAIBlock,
		...(docChangeCountUntilLimit < DOC_CHANGE_COUNT_LIMIT && {
			docChangeCountUntilLimit: docChangeCountUntilLimit + 1,
		}),
	};
};

const handleSelectionChanged = (
	tr: ReadonlyTransaction,
	pluginState: AISpellingGrammarPluginState,
): AISpellingGrammarPluginState => {
	const { decorationSet } = pluginState;
	return {
		...pluginState,
		decorationSet: regenerateSelectedSpellingDecorations({
			decorationSet,
			tr,
			pluginState,
		}),
	};
};

export const createInitialState =
	({
		proactiveAIApiUrl,
		documentSGChecker,
		defaultToggledState,
	}: {
		proactiveAIApiUrl: string;
		documentSGChecker?: DocumentSGChecker;
		defaultToggledState?: boolean;
	}) =>
	(state: EditorState) => {
		const { proactiveAIBlocks } = getAllProactiveAIBlocks(state);
		const initialToggleState = !!defaultToggledState;
		return {
			proactiveAIBlocks,
			initialToggleState,
			isSpellingAndGrammarEnabled: initialToggleState,
			decorationSet: DecorationSet.empty,
			proactiveAIApiUrl,
			dismissedWords: new Set<string>(),
			toggleCount: initialToggleState ? 1 : 0,
			insertionCount: 0,
			splitParagraphIntoSentences: canSplitParagraphIntoSentences(),
			documentSGChecker,
			docChangeCountUntilLimit: 0,
			isFirstInitiatedEventSent: false,
		};
	};

export const { createPluginState, createCommand, getPluginState } = pluginFactory(
	aiSpellingGrammarPluginKey,
	reducer,
	{
		onDocChanged: handleDocChanged,
		onSelectionChanged: handleSelectionChanged,
	},
);
