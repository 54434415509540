import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { Node as ProsemirrorNode, ResolvedPos } from '@atlaskit/editor-prosemirror/model';
import { type Fragment, type NodeType, Slice } from '@atlaskit/editor-prosemirror/model';
import type { EditorState, Transaction } from '@atlaskit/editor-prosemirror/state';
import { TextSelection } from '@atlaskit/editor-prosemirror/state';
import { safeInsert } from '@atlaskit/editor-prosemirror/utils';
import { type EditorView } from '@atlaskit/editor-prosemirror/view';

import { addAnalytics } from '../../analytics/utils';
import { type Positions } from '../../config-items/config-items';
/**
 * Figure out what to do with these, possibly export/shift into editor-common
 * during move into editor-core
 */
import {
	findFirstReplaceStep,
	getValidEndTextPosition,
} from '../../pm-plugins/decoration/utils/step-utils';
import { getSupportedNodesForAIModal } from '../../utils/ai-button';

/**
 * Unsure if we need this - add this 'hook' for now, started with just meta
 * but also folded in `setSelection` logic to reduce that complexity
 *
 * DO NOT pass transaction around beyond here
 */
export function beforeDispatchAITransaction(tr: Transaction, editorState: EditorState) {
	/**
	 * DO NOT REMOVE isAiContentTransformation from transaciton meta.
	 * Proactive AI S+G rely on isAiContentTransformation meta to
	 * 	ignore changes in document made by AI modal.
	 * Why ignore it?
	 * - Response returned from AI does not require Spelling and Grammar check.
	 * - We have assumed that it's already correct from S+G perspective.
	 */
	tr.setMeta('isAiContentTransformation', true);

	const firstReplaceStep = findFirstReplaceStep(tr.steps);
	if (!firstReplaceStep) {
		// Ideally we should always have replace step for for AI transaction.
		// But in case if there isn't we want to fire analytic event so we can monitor it.
		addAnalytics({
			editorState,
			tr,
			payload: {
				action: 'failed',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'experienceApplication',
				attributes: {
					errorType: 'replaceStepNotFound',
				},
				eventType: EVENT_TYPE.OPERATIONAL,
			},
		});
		return tr;
	}

	const validPositionToSet = getValidEndTextPosition({
		step: firstReplaceStep,
		doc: tr.doc,
	});

	if (validPositionToSet !== 0) {
		tr.setSelection(TextSelection.create(tr.doc, validPositionToSet));
	}

	return tr;
}

//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
export function beforeDispatchingTransactionForBlocks(
	fragment: Fragment,
	tr: Transaction,
	state: EditorState,
) {
	const supportedNodeTypes = getSupportedNodesForAIModal(state);
	// Here we are checking if there is text node at first level.
	// Then we will highlight all nodes.
	let areTopLevelBlockElements = true;
	fragment.forEach((node) => {
		areTopLevelBlockElements = areTopLevelBlockElements && supportedNodeTypes.has(node.type);
	});

	if (areTopLevelBlockElements) {
		tr.setMeta('isAiContentTransformationForBlocks', true);
	}
}

export function beforeDispatchAIRovoAgentTransaction(tr: Transaction) {
	/**
	 * DO NOT REMOVE
	 * This is used when distinguishing content generated by a Rovo agent
	 */
	tr.setMeta('isAiRovoAgentContentTransformation', true);

	return tr;
}
function getLastContentDepth({ pmFragment }: { pmFragment: Fragment }) {
	let depth = 0;
	let currentNode: Fragment | ProsemirrorNode = pmFragment;

	while (currentNode.firstChild) {
		depth++;
		currentNode = currentNode.firstChild;
	}

	return depth;
}

function isSelectionAtParagraphStart($pos: ResolvedPos): boolean {
	const node = $pos.parent;
	return (
		!!node && (node.type.name === 'paragraph' || node.type.name === 'heading') && !$pos.parentOffset
	);
}

function isFragmentSingleParagraph(fragment: Fragment): boolean {
	return fragment.childCount === 1 && fragment.firstChild!.type.name === 'paragraph';
}

function isSelectionInTaskOrDecisionNode($pos: ResolvedPos): boolean {
	const node = $pos.parent;
	return (
		!!node &&
		(node.type.name === 'decisionList' ||
			node.type.name === 'decisionItem' ||
			node.type.name === 'taskList' ||
			node.type.name === 'taskItem')
	);
}

//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
export const replaceWithAIContentForBlock = ({
	editorView,
	positions,
	pmFragment,
	triggeredFor,
}: {
	editorView: EditorView;
	positions: Positions;
	pmFragment: Fragment;
	triggeredFor: NodeType;
}): Transaction => {
	const { state } = editorView;
	let transaction = state.tr;

	const slice = new Slice(pmFragment, 0, 0);
	try {
		if (
			triggeredFor?.name === 'table' ||
			triggeredFor?.name === 'tableCell' ||
			triggeredFor?.name === 'tableHeader'
		) {
			const { selection } = state;
			const newCellNodes: ProsemirrorNode[] = [];
			pmFragment.descendants((node) => {
				if (node.type.name === 'tableCell' || node.type.name === 'tableHeader') {
					newCellNodes.push(node);
					return false;
				}
				return true;
			});
			// Can't rely on isCellSelection because currently we set table as node selection.
			// Post experiment, we will start selecting whole table, so we will have cell selection.
			// Then we won't need this condition.
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if ((selection as any).$anchorCell) {
				const sortedRanges = Array.from(selection.ranges).sort((rangeA, rangeB) =>
					rangeA.$from.pos > rangeB.$from.pos ? 1 : -1,
				);
				const mapFrom = transaction.mapping.maps.length;
				sortedRanges.forEach((range, index) => {
					const cellNodeContent = newCellNodes[index].content;
					const slice = new Slice(cellNodeContent, 0, 0);
					transaction.replace(
						transaction.mapping.slice(mapFrom).map(range.$from.pos),
						transaction.mapping.slice(mapFrom).map(range.$to.pos),
						slice,
					);
				});
			} else {
				const slice = new Slice(pmFragment, 0, 0);
				transaction.replaceRange(positions[0], positions[1], slice);
			}
		} else {
			transaction.replaceRange(positions[0], positions[1], slice);
		}
		// If you can't replace the range, use safeInsert
	} catch {
		safeInsert(pmFragment, undefined, true)(transaction);
		const tr = addAnalytics({
			editorState: state,
			tr: state.tr,
			payload: {
				action: 'fallback',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'proseMirrorReplace',
				eventType: EVENT_TYPE.OPERATIONAL,
				attributes: {},
			},
		});

		editorView.dispatch(tr);
	}

	beforeDispatchingTransactionForBlocks(pmFragment, transaction, editorView.state);
	beforeDispatchAITransaction(transaction, state);
	return transaction;
};

export const replaceWithAIContent = ({
	editorView,
	positions,
	pmFragment,
	options = {
		replaceDocOnEmptySelection: true,
	},
}: {
	editorView: EditorView;
	positions: Positions;
	pmFragment: Fragment;
	options?: {
		replaceDocOnEmptySelection?: boolean;
	};
}): Transaction => {
	const { state } = editorView;
	const transaction = state.tr;
	const endPositionDepth = state.doc.resolve(positions[1]).depth;
	const lastFragmentDepth = getLastContentDepth({ pmFragment });

	const sliceOpenEnd = Math.min(endPositionDepth, lastFragmentDepth);

	const slice = new Slice(pmFragment, 1, sliceOpenEnd);

	// We check if the positions are the same if so we replace the whole document
	// This is a quick spike so real implementation might need to be different
	// You might need to create a new replace action
	if (options.replaceDocOnEmptySelection && positions[0] === positions[1]) {
		transaction.replaceRange(0, state.doc.content.size, slice);
	} else {
		try {
			transaction.replaceRange(positions[0], positions[1], slice);
			// If you can't replace the range, use safeInsert
		} catch {
			safeInsert(pmFragment, undefined, true)(transaction);
			const tr = addAnalytics({
				editorState: state,
				tr: state.tr,
				payload: {
					action: 'fallback',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'proseMirrorReplace',
					eventType: EVENT_TYPE.OPERATIONAL,
					attributes: {},
				},
			});

			editorView.dispatch(tr);
		}
	}

	beforeDispatchAITransaction(transaction, state);
	return transaction;
};

export const insertAIContentAtCurrentPosition = ({
	editorView,
	positions,
	pmFragment,
}: {
	editorView: EditorView;
	positions: Positions;
	pmFragment: Fragment;
}): Transaction => {
	const { state } = editorView;
	const transaction = state.tr;
	const pos = positions[0];
	const $pos = state.doc.resolve(pos);
	const isParagraphStart = isSelectionAtParagraphStart($pos);
	// if selection is at the beginning of paragraph,
	// insert before the cursor so that it inserts without an extra new line
	const insertPos = isParagraphStart ? pos - 1 : pos;

	// if selection is inside decision or task node AND pmFragment is a single paragraph
	// strip the paragraph so it can insert inside the decision/task
	let fragmentToInsert = pmFragment;
	if (isSelectionInTaskOrDecisionNode($pos) && isFragmentSingleParagraph(pmFragment)) {
		fragmentToInsert = pmFragment.firstChild!.content;
	}

	transaction.insert(insertPos, fragmentToInsert);

	beforeDispatchAITransaction(transaction, state);
	return transaction;
};
