import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';

import type { ParagraphChunk } from '../../utils/diff-match-patch/utils';

import { mdPlusSuggestionGenerator } from './md-plus-suggestion-generator';
import { type ChunkMetadata } from './states';
import { suggestionGenerator } from './suggestion-generator';

export type Suggestion = {
	chunkId: string;
	suggestion: string;
	metadata?: ChunkMetadata;
	chunkIdMap?: IDMap;
};

type SuggestionEntry =
	| { state: 'parsed'; suggestions: Suggestion[] }
	| { state: 'cached'; suggestions: Suggestion[] }
	| {
			state: 'receivedChunkMetadata';
			chunkId: string;
			metadata: {
				editDistanceRatio?: number;
				wordCountRatio?: number;
				originalContentLength?: number;
			};
	  }
	| { state: 'trackedDuration'; duration: number }
	| {
			state: 'failed';
			reason: 'network' | 'backend' | 'aborted' | 'parsing' | 'unhandled';
			errors: string[];
			statusCode: number;
			failedChunkIds: string[];
	  }
	| {
			state: 'purged';
			totalParts: number;
			totalPurgedParts: number;
			purgedChunkIds: string[];
	  }
	| { state: 'done' };

export type SuggestionGenerator = AsyncGenerator<SuggestionEntry>;

// NOTE: It's important that we maintain a consistent API regardless of which endpoint we hit.
// This is a generic interface we should be able to use for any version of the provider.
export type ProactiveAIProvider = (
	paragraphs: Array<ParagraphChunk>,
) => SuggestionGenerator | undefined;

/**
 * This will filter out chunks from being streamed to the service which are not valid.
 */
export const isChunkExcluded = (chunk: ParagraphChunk) =>
	chunk.from === chunk.to || !chunk.text || !chunk.text.trim().length;

/* istanbul ignore next */
// This V1 endpoint is the final endpoint we're targetting for hello release.
export const fetchAIParagraphs =
	(view: EditorView, proactiveAIApiUrl: string, locale: string): ProactiveAIProvider =>
	(paragraphs: Array<ParagraphChunk>) => {
		if (fg('platform_editor_ai_proactive_ai_md_plus')) {
			return mdPlusSuggestionGenerator({
				endpoint: proactiveAIApiUrl,
				editorView: view,
				paragraphs,
			});
		}
		const included = paragraphs.filter((chunk) => !isChunkExcluded(chunk));
		if (!!included.length) {
			return suggestionGenerator({
				endpoint: proactiveAIApiUrl,
				paragraphs: included,
				abortController: new AbortController(),
			});
		}

		return;
	};
