import * as defaultEnvConstants from './post-office-environment/constants';

export {
	usePostOfficeContext,
	PostOfficeContext,
	PostOfficeContextProvider,
} from './post-office-context';
export type {
	PostOfficeContextValue,
	PostOfficeContextConfig as Context,
} from './post-office-context/types';

export { PostOfficeEnvironmentProvider, usePostOfficeEnvironment } from './post-office-environment';
export const {
	REMOTE_MODULE_URI_LOCAL,
	REMOTE_MODULE_URI_PROD,
	REMOTE_MODULE_URI_STG,
	API_ORIGIN_LOCAL,
	API_PATHNAME_LOCAL,
	API_PATHNAME_STG_PROD,
} = defaultEnvConstants;
export type {
	PlacementConfigOverrides,
	PostOfficeEnvironmentValues,
} from './post-office-environment/types';

export { useStableObject } from './util/use-stable-object';
export { usePostOfficeContextScope as useContextScope } from './post-office-context-scope';
export type { Scopes } from './post-office-context-scope/types';
export { useMessagePlacementHooks } from './post-office/message-placement';
export { PostOfficeProvider } from './post-office';
export type {
	Env,
	PostOfficeContextConfig,
	PostOfficeEnvironmentConfig,
	PostOfficeProviderProps,
} from './post-office/types';
export { usePostOfficeRouteUpdate } from './post-office-route/listener';
export {
	PostOfficeRouteContext,
	type PostOfficeRouteState,
	usePostOfficeRoute,
} from './post-office-route/exports';

export type {
	DismissCallback,
	ReadCallback,
	ViewedCallback,
	ClickedCallback,
} from './post-office/message-placement';

export { usePostOfficeScreenPlacement, usePostOfficeScreen } from './post-office-screen-context';
