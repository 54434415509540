import {
	init,
	type GenericAnalyticWebClientPromise,
} from '@atlassian/react-ufo/interaction-metrics-init';
import { type Config } from '@atlassian/react-ufo/config';
import { configure as configureSsrTiming } from '@atlassian/react-ufo/ssr';

import { fg } from '@confluence/feature-gating';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getMark } from '@confluence/performance';
import type { SessionDataType } from '@confluence/session-data';

import { additionalPayloadData } from './additionalPayloadData';
import { getSSRDoneTime, getSSRTimings } from './ssr';
import { initUFOTrace } from './ufoTrace';

// If you use any FF during UFO initialization,
// please pass the feature flag to __INIT_UFO_DATA__ in the confluence/next/packages/ssr-app/src/index.tsx
// and update initUFOWithSsrData as well

const getConfig = (sessionData: SessionDataType): Partial<Config> | undefined => {
	const config = sessionData.featureFlagClient.getRawValue('confluence.frontend.ufo.config', {
		default: '{}',
	});
	try {
		return JSON.parse(String(config));
	} catch (e) {}
};

function runConfigureSsrTiming() {
	configureSsrTiming({
		getDoneMark: () => getMark('CFP-63.ssr-ttr') ?? null, // from confluence/next/packages/browser-metrics/src/initializeBrowserMetrics.ts
		// TODO: add correct functions to `getTimings` and `getFeatureFlags`
		getTimings: () => null,
		getFeatureFlags: () => null,
	});
}

function initializeUFO({
	shard,
	cloudId,
	config,
	featureFlags: {
		devToolsEnabled,
		stopVCAtInteractionFinish,
		enableTracePropagation,
		captureLateReRenders,
		enableSegmentHighlighting,
	},
}: {
	shard: string;
	cloudId: string;
	config: Record<string, any>;
	featureFlags: {
		devToolsEnabled: boolean;
		stopVCAtInteractionFinish: boolean;
		enableTracePropagation: boolean;
		captureLateReRenders: boolean;
		enableSegmentHighlighting: boolean;
	};
}) {
	runConfigureSsrTiming();

	if (enableTracePropagation) {
		initUFOTrace();
	}

	init(getAnalyticsWebClient() as Promise<GenericAnalyticWebClientPromise>, {
		captureLateReRenders,
		enableSegmentHighlighting,
		product: 'confluence',
		region: shard || 'unknown',
		vc: {
			enabled: true,
			heatmapSize: 200,
			oldDomUpdates: false,
			selectorConfig: {
				id: false,
				role: false,
				className: true,
				testId: false,
			},
			devToolsEnabled,
			stopVCAtInteractionFinish,
		},
		...config,
		ssr: { getSSRDoneTime, getSSRTimings },
		additionalPayloadData: (interaction) =>
			additionalPayloadData(interaction, {
				cloudId,
				isShardPreProd: shard ? shard.startsWith('conf-prod-us-preprod') : false,
			}),
	});
}

// This function gets called when ufo data is provided by SSR
export function initUFOWithSsrData({
	shard,
	cloudId,
	featureFlags,
}: {
	shard: string;
	cloudId: string;
	featureFlags: Record<string, any>;
}) {
	const config = featureFlags['confluence.frontend.ufo.config'];
	if (!config || !config?.enabled) {
		return false;
	}

	initializeUFO({
		shard,
		cloudId,
		config,
		featureFlags: {
			devToolsEnabled: featureFlags['confluence.frontend.enable.ttvc.devtool'] || false,
			stopVCAtInteractionFinish: featureFlags['stop_vc_at_interaction_finish'] || false,
			enableTracePropagation: featureFlags['confluence_fe_enable_trace_propagation'] || false,
			captureLateReRenders: featureFlags['ufo_capture_late_rerenders_confluence'] || false,
			enableSegmentHighlighting:
				featureFlags['ufo_enable_segment_highlighting_confluence'] || false,
		},
	});
	return true;
}

export function initUFO(sessionData: SessionDataType) {
	const config = getConfig(sessionData);
	if (!config || !config?.enabled) {
		return;
	}

	const devToolsEnabled = sessionData.featureFlagClient.getBooleanValue(
		'confluence.frontend.enable.ttvc.devtool',
		{ default: false },
	);
	const stopVCAtInteractionFinish = fg('stop_vc_at_interaction_finish');
	const enableTracePropagation = fg('confluence_fe_enable_trace_propagation');
	const enableSegmentHighlighting = fg('ufo_enable_segment_highlighting_confluence');

	const captureLateReRenders = fg('ufo_capture_late_rerenders_confluence');

	initializeUFO({
		shard: sessionData.shard,
		cloudId: sessionData.cloudId,
		config,
		featureFlags: {
			devToolsEnabled,
			stopVCAtInteractionFinish,
			enableTracePropagation,
			captureLateReRenders,
			enableSegmentHighlighting,
		},
	});
}
