import { SupportedTranslationLanguages } from '../prebuilt/translations/types';

export enum CONFIG_ITEM_KEYS {
	ADD_STRUCTURE = 'Add structure',
	BRAINSTORM = 'Brainstorm',
	CHANGE_TONE_TO_CASUAL = 'Change tone to casual',
	CHANGE_TONE_TO_EDUCATIONAL = 'Change tone to educational',
	CHANGE_TONE_TO_EMPATHETIC = 'Change tone to empathetic',
	CHANGE_TONE_TO_NEUTRAL = 'Change tone to neutral',
	CHANGE_TONE_TO_PROFESSIONAL = 'Change tone to professional',
	CREATE_PULL_REQUEST_DESCRIPTION = 'Create pull request description',
	DESCRIBE_YOUR_PULL_REQUEST = 'Describe your pull request',
	FIND_ACTION_ITEMS = 'Find action items',
	FIX_SPELLING_AND_GRAMMAR = 'Fix spelling & grammar',
	FREE_GENERATE = 'AI (Atlassian Intelligence)',
	IMPROVE_WRITING = 'Improve writing',
	MAKE_SHORTER = 'Make shorter',
	ROVO_AGENT = 'Rovo agent',
	SHORTEN_UPDATE = 'Shorten update',
	SUGGEST_A_TITLE = 'Suggest a title',
	SUGGEST_REPONSE = 'Suggest a response',
	SUMMARISE_WRITING = 'Summarize writing',
	TRANSLATE_TO_CHINESE_SIMPLIFIED = `Translate to ${SupportedTranslationLanguages.ChineseSimplified}`,
	TRANSLATE_TO_CHINESE_TRADITIONAL = `Translate to ${SupportedTranslationLanguages.ChineseTraditional}`,
	TRANSLATE_TO_CZECH = `Translate to ${SupportedTranslationLanguages.Czech}`,
	TRANSLATE_TO_DANISH = `Translate to ${SupportedTranslationLanguages.Danish}`,
	TRANSLATE_TO_DUTCH = `Translate to ${SupportedTranslationLanguages.Dutch}`,
	TRANSLATE_TO_ENGLISH = `Translate to ${SupportedTranslationLanguages.English}`,
	TRANSLATE_TO_FINNISH = `Translate to ${SupportedTranslationLanguages.Finnish}`,
	TRANSLATE_TO_FRENCH = `Translate to ${SupportedTranslationLanguages.French}`,
	TRANSLATE_TO_GERMAN = `Translate to ${SupportedTranslationLanguages.German}`,
	TRANSLATE_TO_HUNGARIAN = `Translate to ${SupportedTranslationLanguages.Hungarian}`,
	TRANSLATE_TO_ITALIAN = `Translate to ${SupportedTranslationLanguages.Italian}`,
	TRANSLATE_TO_JAPANESE = `Translate to ${SupportedTranslationLanguages.Japanese}`,
	TRANSLATE_TO_KOREAN = `Translate to ${SupportedTranslationLanguages.Korean}`,
	TRANSLATE_TO_NORWEGIAN = `Translate to ${SupportedTranslationLanguages.Norwegian}`,
	TRANSLATE_TO_POLISH = `Translate to ${SupportedTranslationLanguages.Polish}`,
	TRANSLATE_TO_PORTUGUESE_BRAZILIAN = `Translate to ${SupportedTranslationLanguages.PortugueseBrazilian}`,
	TRANSLATE_TO_RUSSIAN = `Translate to ${SupportedTranslationLanguages.Russian}`,
	TRANSLATE_TO_SPANISH = `Translate to ${SupportedTranslationLanguages.Spanish}`,
	TRANSLATE_TO_SWEDISH = `Translate to ${SupportedTranslationLanguages.Swedish}`,
	TRANSLATE_TO_TURKISH = `Translate to ${SupportedTranslationLanguages.Turkish}`,
	TRANSLATE_TO_THAI = `Translate to ${SupportedTranslationLanguages.Thai}`,
	TRANSLATE_TO_UKRAINIAN = `Translate to ${SupportedTranslationLanguages.Ukrainian}`,
	TRANSLATE_TO_VIETNAMESE = `Translate to ${SupportedTranslationLanguages.Vietnamese}`,
	EXPERIMENTAL_PROMPT_PLACEHOLDER = 'Experimental prompt placeholder',
}
