import { query } from '@confluence/query-preloader-tools';
import { preloadSpacesList } from '@confluence/space-utils/entry-points/preloadSpacesList';

import { HomeLeftPanelAdministrationQuery } from '../LeftPanel/HomeLeftPanelAdministrationQuery.graphql';

export const preloadHomeNavigation = ({
	isSpaceAliasFFEnabled,
	isLoggedIn,
	isLicensed,
	isMyVisitedSpacesEnabled,
}: {
	isSpaceAliasFFEnabled?: boolean;
	isLoggedIn: boolean;
	isLicensed: boolean;
	isMyVisitedSpacesEnabled: boolean;
}) => {
	// Spaces list and Administration section shown in the left navigation
	return Promise.all([
		preloadSpacesList({ isLicensed, isMyVisitedSpacesEnabled, isSpaceAliasFFEnabled }),
		preloadLeftPanelAdministrationQuery({ isLoggedIn }),
	]);
};

const preloadLeftPanelAdministrationQuery = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
	const queryOptions = {
		query: HomeLeftPanelAdministrationQuery,
		skip: !isLoggedIn,
	};
	return query(queryOptions);
};
