/* GeneratedContentHash: 20c1ed5c6fa3c6db8a451c1ebcc4d7bd8be732220a63e32a947d22655aebf9d8 */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
#
# Generate source: local/cli/src/commands/generate-all/i18n-extract.ts
*/

import defaultMessages from './generated/en.json';
import mapLocaleToPrsLocale from '@atlaskit/prs-locale-mapper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messagesLoader = async (locale: string): Promise<any> => {
	const normalizedLocale = mapLocaleToPrsLocale(locale);
	switch (normalizedLocale) {
		case 'cs':
			return (await import('./generated/cs.json')).default;
		case 'da':
			return (await import('./generated/da.json')).default;
		case 'de':
			return (await import('./generated/de.json')).default;
		case 'en-GB':
			return (await import('./generated/en-GB.json')).default;
		case 'en-US':
			return (await import('./generated/en-US.json')).default;
		case 'en-XA':
			return (await import('./generated/en-XA.json')).default;
		case 'en-XB':
			return (await import('./generated/en-XB.json')).default;
		case 'en-ZZ':
			return (await import('./generated/en-ZZ.json')).default;
		case 'en':
			return (await import('./generated/en.json')).default;
		case 'es':
			return (await import('./generated/es.json')).default;
		case 'et':
			return (await import('./generated/et.json')).default;
		case 'fi':
			return (await import('./generated/fi.json')).default;
		case 'fr':
			return (await import('./generated/fr.json')).default;
		case 'hu':
			return (await import('./generated/hu.json')).default;
		case 'it':
			return (await import('./generated/it.json')).default;
		case 'ja':
			return (await import('./generated/ja.json')).default;
		case 'ko':
			return (await import('./generated/ko.json')).default;
		case 'nb':
			return (await import('./generated/nb.json')).default;
		case 'nl':
			return (await import('./generated/nl.json')).default;
		case 'pl':
			return (await import('./generated/pl.json')).default;
		case 'pt-BR':
			return (await import('./generated/pt-BR.json')).default;
		case 'ro':
			return (await import('./generated/ro.json')).default;
		case 'ru':
			return (await import('./generated/ru.json')).default;
		case 'sv':
			return (await import('./generated/sv.json')).default;
		case 'th':
			return (await import('./generated/th.json')).default;
		case 'tr':
			return (await import('./generated/tr.json')).default;
		case 'uk':
			return (await import('./generated/uk.json')).default;
		case 'vi':
			return (await import('./generated/vi.json')).default;
		case 'zh-CN':
			return (await import('./generated/zh-CN.json')).default;
		case 'zh-TW':
			return (await import('./generated/zh-TW.json')).default;
		default:
			return defaultMessages;
	}
};
