import { filter } from '@atlaskit/adf-utils/traverse';
import type { ADDoc } from '@atlaskit/editor-common/validator';

import { query } from '@confluence/query-preloader-tools';

import { MentionsUsersQuery } from './MentionsUsersQuery.graphql';
import type {
	usersV2Bulk as MentionsUsersQueryType,
	usersV2BulkVariables as MentionsUsersQueryVariables,
} from './__types__/MentionsUsersQuery';

const ARI_PREFIX = 'ari:cloud:identity::user/';

export const FG_PRELOAD_MENTIONS = 'confluence_frontend_editor_preload_mentions';
export const MENTIONS_LOOKUP_LIMIT = 50;

export type MentionUserEntry = {
	id?: string | null;
	name?: string | null;
};

export const preloadMentionsUsers = async (userIds: string[]): Promise<MentionUserEntry[]> => {
	const ids = userIds.map((id) => `${ARI_PREFIX}${id}`);

	const { data } = await query<MentionsUsersQueryType, MentionsUsersQueryVariables>({
		query: MentionsUsersQuery,
		variables: {
			ids,
		},
	});

	return (data?.usersV2 || []).map((user) => ({
		id: user?.id?.replace(ARI_PREFIX, ''),
		name: user?.name,
	}));
};

export const preloadMentionsUsersFromADF = async (
	document?: string | null,
): Promise<string | null> => {
	if (!document) {
		return null;
	}

	try {
		const adf = JSON.parse(document) as ADDoc;

		const mentions = filter(adf, ({ type, attrs }) => type === 'mention' && attrs?.id);
		const userIdSet = new Set<string>();
		mentions.forEach(({ attrs }) => {
			if (attrs?.id) {
				userIdSet.add(attrs.id);
			}
		});

		if (userIdSet.size === 0) {
			return document;
		}

		const mentionUsers = await preloadMentionsUsers(
			Array.from(userIdSet).slice(0, MENTIONS_LOOKUP_LIMIT),
		);
		const mentionUserMap = new Map<string, MentionUserEntry>(
			mentionUsers.map((user) => [user.id || '', user]),
		);

		mentions.forEach((mention) => {
			const mentionUser = mentionUserMap.get(mention.attrs?.id || '');
			if (mentionUser) {
				mention.attrs!.text = `@${mentionUser.name}`;
			}
		});

		return JSON.stringify(adf);
	} catch (e) {
		// NOOP as this may happen when document is empty or invalid,
		// so we don't have anything to preload anyway.
	}

	return document;
};
