import { getExtensionListForSSR } from '@atlassian/forge-ui/provider';

import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { AccessStatus } from '@confluence/session-data';
import { preloadSpaceDetail } from '@confluence/space-utils';
import { ssrPrepareErrorsClient } from '@confluence/ssr-utilities';

import { preloadUseStaticContext } from '../display-conditions/preloadUseStaticContext';
import { filterExtensionsByDisplayConditions } from '../display-conditions/useExtensionsFilteredByDisplayConditions';

import { checkAccessNarrowingFlagEnabled, type UseExtensionListProps } from './useExtensionList';
import { getUseExtensionListOptions } from './getUseExtensionListOptions';

export async function preloadUseExtensionList({
	contentId,
	spaceKey,
	moduleType,
	queryOptions,
	expandAppOwner = false,
}: UseExtensionListProps & { contentId?: string; spaceKey: string }) {
	const { cloudId, activationId, featureFlags, isLoggedIn, accessStatus, locale } =
		await getPreloaderFnContext();

	const isSpaceAliasFFEnabled = Boolean(featureFlags['confluence.frontend.space.alias']);
	const isAccessNarrowingFlagEnabled = checkAccessNarrowingFlagEnabled(
		Boolean(featureFlags['confluence.frontend.ecosystem.access.narrrowing']),
		moduleType,
	);
	const isAnonymous = !isLoggedIn;
	const isExternalCollaborator = accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;

	const [staticContext, { data }] = await Promise.all([
		preloadUseStaticContext({ spaceKey }),
		preloadSpaceDetail(spaceKey, isSpaceAliasFFEnabled),
	]);
	const dataClassificationTags = data?.space?.dataClassificationTags;

	try {
		const extensions = await getExtensionListForSSR({
			...getUseExtensionListOptions({
				cloudId,
				activationId,
				locale,
				dataClassificationTags,
				isAccessNarrowingFlagEnabled,
				expandAppOwner,
				moduleType,
				queryOptions,
				skip: isAnonymous || isExternalCollaborator,
			}),
			queryFn: query,
		});
		if (extensions) {
			const fetchContext = { contentId, spaceKey };
			const filteredResult = await filterExtensionsByDisplayConditions(
				extensions,
				staticContext,
				fetchContext,
			);
			window.__SSR_FORGE_EXTENSIONS__ = window.__SSR_FORGE_EXTENSIONS__ || {};
			window.__SSR_FORGE_EXTENSIONS__[moduleType] = {
				extensions: filteredResult.extsToDisplay,
				error: filteredResult.conditionsError,
			};
		}
	} catch (e) {
		ssrPrepareErrorsClient.submitError(
			new Error(`Filed to get forge extension list: ${e.message}`),
		);
	}
}
