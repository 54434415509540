import { PostOfficeAnalyticsContext } from '@atlaskit/analytics-namespaced-context';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import {
	type Context,
	usePostOfficeContext,
	usePostOfficeRoute,
	useStableObject,
} from '@atlassian/post-office-context';
import { UFOv1, UFOv2 } from '@atlassian/post-office-frontend-performance-tracking';
import { type FunctionComponent, type ReactNode, useCallback, useMemo } from 'react';

import { ANALYTICS_SOURCE } from './constants';
import { requestInitialData } from './initialDataClient';
import { useBuildBasePath } from './utils/buildBasePath';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Confluence Local Consumption - package.json is not listed within the file list of project Projects must list all files or use an 'include' pattern.
import { version as postOfficeFrontendVersion } from '../package.json';

interface BaseProps {
	placementId: string;
	consumptionType: 'remote' | 'local';
	disableInitialData?: boolean;
	onEvent?: PlacementOnEvent;
}

const useInitialDataIfEnabled = (
	placementId: string,
	context: Context,
	baseUrl: string | undefined,
	path: string,
	disableInitialData: boolean,
) => {
	return useMemo(
		() =>
			disableInitialData
				? undefined
				: requestInitialData(placementId, context, global.fetch, {
						baseUrl,
						path,
					}),
		[disableInitialData, baseUrl, path, placementId, context],
	);
};

/**
 * Callback function to listen to the different stages of the placement
 */
export type PlacementOnEvent = (event: {
	type:
		| 'fetch-complete'
		| 'fetch-error'
		| 'render-complete'
		| 'render-error'
		| 'initial-data-complete'
		| 'initial-data-error'
		| 'component-ready'
		| 'component-error'
		| 'unmount';
	error?: unknown;
}) => void;

type RenderChildrenProps = {
	initialData: Promise<Response> | undefined;
	context: Context;
	onEvent: PlacementOnEvent;
};
type PropsWithRenderChildren<P = unknown> = P & {
	children: (props: RenderChildrenProps) => ReactNode;
};

type Props = PropsWithRenderChildren<BaseProps>;

const Placement: FunctionComponent<Props> = ({
	placementId,
	consumptionType,
	onEvent: placementOnEvent,
	disableInitialData = false,
	children,
}) => {
	const isUFOEnabled = FeatureGates.checkGate('enable_ufo_tracking_in_post_office');

	const postOfficeRoute = usePostOfficeRoute();

	const context = usePostOfficeContext();

	const { baseUrl, path } = useBuildBasePath();

	const stableContextWithRoute = useStableObject({
		...context,
		postOfficeRoute,
	});

	const ufoV1PlacementTracker = useMemo(
		() =>
			isUFOEnabled
				? new UFOv1.PlacementPerformanceTracker(placementId, consumptionType)
				: undefined,
		[isUFOEnabled, placementId, consumptionType],
	);

	// Callback function to listen to the different stages of the remote component and track its performance
	const remoteComponentOnEvent = useCallback<PlacementOnEvent>(
		({ type, error }) => {
			placementOnEvent?.({ type, error });
			switch (type) {
				case 'fetch-complete':
					void ufoV1PlacementTracker?.markFetchComplete();
					break;
				case 'fetch-error':
					void ufoV1PlacementTracker?.markFetchFailure(error as Error);
					break;
				case 'render-complete':
					void ufoV1PlacementTracker?.markRenderComplete();
					break;
				case 'render-error':
					void ufoV1PlacementTracker?.markRenderFailure(error as Error);
					break;
				case 'initial-data-complete':
					void ufoV1PlacementTracker?.markInitialDataComplete();
					break;
				case 'initial-data-error':
					void ufoV1PlacementTracker?.markInitialDataFailure(error as Error);
					break;
				case 'component-error':
					void ufoV1PlacementTracker?.markComponentFailure(error as Error);
					break;
				case 'component-ready':
					void ufoV1PlacementTracker?.markComponentReady();
					break;
				case 'unmount':
					void ufoV1PlacementTracker?.markComponentAborted();
					break;
			}
		},
		[placementOnEvent, ufoV1PlacementTracker],
	);

	const initialData = useInitialDataIfEnabled(
		placementId,
		context,
		baseUrl,
		path,
		disableInitialData,
	);

	const placementAnalyticsContext = useMemo(
		() => ({
			source: ANALYTICS_SOURCE,
			componentName: `Placement`,
			attributes: {
				placementId,
				channel: 'in-app',
				postOfficeFrontendVersion,
			},
		}),
		[placementId],
	);

	return (
		<FeatureFlaggedUfoWrapper placementId={placementId} isEnabled={isUFOEnabled}>
			<PostOfficeAnalyticsContext data={placementAnalyticsContext}>
				{children({
					initialData,
					context: stableContextWithRoute,
					onEvent: remoteComponentOnEvent,
				})}
			</PostOfficeAnalyticsContext>
		</FeatureFlaggedUfoWrapper>
	);
};

export default Placement;

interface FeatureFlaggedUfoWrapperProps {
	isEnabled: boolean;
	placementId: string; // Adjust the type according to your actual context structure
	children: ReactNode;
}

const FeatureFlaggedUfoWrapper: FunctionComponent<FeatureFlaggedUfoWrapperProps> = ({
	isEnabled,
	placementId,
	children,
}) => {
	if (isEnabled) {
		return (
			<UFOv2.UFOSegment name={UFOv2.SegmentNames.PLACEMENT}>
				<UFOv2.UFOSegment name={`${UFOv2.SegmentNames.PLACEMENT}.${placementId}`}>
					{children}
				</UFOv2.UFOSegment>
			</UFOv2.UFOSegment>
		);
	}
	return <>{children}</>;
};
