/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import {
	Fragment,
	useRef,
	type ReactNode,
	Suspense,
	useContext,
	useEffect,
	useMemo,
	useState,
	forwardRef,
} from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { Box, xcss } from '@atlaskit/primitives';
import { CURRENT_SURFACE_CSS_VAR, useThemeObserver } from '@atlaskit/tokens';
import { fg } from '@atlaskit/platform-feature-flags';

import { EnvironmentContext } from '../../context';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../../analytics';
import { useCustomUITunnelsList } from '../../web-client';
import { parseExtensionId, useMemoizedStateObject } from '../../utils';

import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { type CoreDataInner } from '@atlassian/forge-ui-types';

import stringify from 'fast-json-stable-stringify';

import { TracingProvider } from '../../error-reporting';
import {
	createSrcFromExtension,
	getBrowserLocale,
	getBrowserTimezone,
	getExtensionEntryPoint,
	getResolverContext,
	getSrcUrl,
	getViewContext,
} from './utils';
import type { HTMLCustomIFrameElement, ViewContext, IframeProps } from './types';
import { InnerIframe } from './InnerIframe';
import { getLoadingComponent } from './utils/getLoadingComponent';
import { useForgeUiAnalyticsEvent } from '../../analytics/useForgeUiAnalyticsEvent';

const ReloadOnContextChangeWrapper = ({
	context,
	children,
}: {
	context: ViewContext;
	children: ReactNode;
}) => <Fragment key={stringify(context)}>{children}</Fragment>;

export const Iframe = forwardRef<HTMLCustomIFrameElement, IframeProps>(
	(
		{
			accountId,
			extension,
			contextIds,
			apolloClient,
			components,
			coreData,
			extensionData,
			loadingComponent,
			onLoad,
			height = '100%',
			width = '100%',
			bridge,
			customBridgeMethods,
			isResizable = true,
			entryPoint,
			extensionPayload,
			getContextToken,
			timezone,
			locale,
			isHidden = false,
			isInModal = false,
		},
		iframeRef,
	) => {
		const environment = useContext(EnvironmentContext);

		// ARKEN-856: useThemeObserver() is a bit noisy, it returns copies of the same theme which breaks useBridge() dependencies
		// We use useMemoizedStateObject() to properly memoize its output doing a deep-equality check
		const theme = useMemoizedStateObject(useThemeObserver());
		const iframeWrapperRef = useRef<HTMLDivElement>(null);

		const [surfaceColor, setSurfaceColor] = useState<string | null>(null);
		const { trackExtensionLoaded } = useForgeUiAnalyticsEvent();

		useEffect(() => {
			if (!iframeWrapperRef.current) {
				return;
			}
			const nextSurfaceColor = getComputedStyle(iframeWrapperRef.current).getPropertyValue(
				CURRENT_SURFACE_CSS_VAR,
			);

			setSurfaceColor(nextSurfaceColor);
		}, [theme]);

		const { appId, moduleKey } = parseExtensionId(extension.id);
		const { environmentId, environmentType } = extension;

		const extensionEntryPoint = getExtensionEntryPoint(extension, entryPoint);

		const defaultSrc = useMemo(
			() => createSrcFromExtension(appId, extension, environment, extensionEntryPoint),
			[appId, extension, environment, extensionEntryPoint],
		);

		const { tunnels, loading } = useCustomUITunnelsList({
			client: apolloClient,
			appId,
			environmentId,
			environmentType,
		});

		const { createAnalyticsEvent } = useAnalyticsEvents();
		useEffect(() => {
			if (extension?.properties?.render !== 'native') {
				if (fg('platform.forge-ui.use-new-event-schema')) {
					// Regular "loaded" analytics event
					trackExtensionLoaded({
						forgeEnvironment: extension?.environmentType,
						renderType: 'CustomUI',
					});
				}

				// Iframe is reused in the `WebRuntime` component. This event should not be logged if the app is UI Kit
				// TODO: remove this event once we have switched over to the new event schema
				// https://ecosystem-platform.atlassian.net/browse/EXT-2196
				createAnalyticsEvent({
					eventType: UI_EVENT_TYPE,
					data: {
						action: 'viewed',
						actionSubject: 'forgeUIExtension',
						attributes: {
							isCustomUI: true,
							forgeEnvironment: extension?.environmentType,
						},
					},
				}).fire(FORGE_UI_ANALYTICS_CHANNEL);
			}
			// Removing `trackExtensionLoaded` as it's potentially causing an infinite loop
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			createAnalyticsEvent,
			extension?.environmentType,
			extension?.properties?.render,
			extension?.type,
		]);

		const [isThreeLOPromptVisible, setIsThreeLOPromptVisible] = useState(false);

		const coreDataInner: CoreDataInner = {
			...coreData,
			environmentId,
			environmentType,
			moduleKey,
			siteUrl: window.location.origin,
		};

		const resizingEnabled = isResizable && !extension.properties.viewportSize;

		return (
			<Box
				ref={iframeWrapperRef}
				testId="hosted-resources-iframe-container"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					position: 'relative',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					minHeight: isThreeLOPromptVisible || isHidden ? undefined : height,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					height: resizingEnabled || isThreeLOPromptVisible || isHidden ? undefined : height,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					width,
				})}
			>
				{loading && (
					<Suspense fallback={null}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
						<Box xcss={xcss({ width: '100%', height: '100%' })}>
							{/* This hides another loading spinner we have that is shown while we are fetching the list of current active tunnels.
              This is not needed for UI Kit 2 (where isHidden = true) since we’ll already show a loading spinner with the RendererNext component */}
							{loading && !isHidden && getLoadingComponent(loadingComponent)}
						</Box>
					</Suspense>
				)}
				{!loading && (
					<TracingProvider>
						<ReloadOnContextChangeWrapper
							context={getViewContext(
								getResolverContext(coreDataInner, extensionData),
								accountId,
								extension,
								timezone ?? getBrowserTimezone(),
								locale ?? getBrowserLocale(),
							)}
						>
							<InnerIframe
								accountId={accountId}
								extension={extension}
								contextIds={contextIds}
								apolloClient={apolloClient}
								components={components}
								coreData={coreDataInner}
								extensionData={extensionData}
								loadingComponent={loadingComponent}
								onLoad={onLoad}
								height={height}
								width={width}
								src={getSrcUrl(tunnels, extensionEntryPoint, defaultSrc)}
								bridge={bridge}
								customBridgeMethods={customBridgeMethods}
								isResizable={resizingEnabled}
								getContextToken={getContextToken}
								timezone={timezone}
								locale={locale}
								setIsThreeLOPromptVisible={setIsThreeLOPromptVisible}
								extensionPayload={extensionPayload}
								isHidden={isHidden}
								theme={theme}
								surfaceColor={surfaceColor}
								appId={appId}
								ref={iframeRef}
								isInModal={isInModal}
							/>
						</ReloadOnContextChangeWrapper>
					</TracingProvider>
				)}
			</Box>
		);
	},
);
