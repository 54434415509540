import FeatureGates from '@atlaskit/feature-gate-js-client';
import {
	type DismissCallback,
	useContextScope,
	useMessagePlacementHooks,
} from '@atlassian/post-office-context';
import {
	type MessageCategory,
	createPlacementComponent,
	useInitialData,
} from '@post-office/placement-common';
import { usePlacementApi } from '@post-office/use-placement-api';
import { useEffect, useState } from 'react';

import { scopes } from './scopes';
import { MessageRenderer } from '../__generated__/client/render';
import { PLACEMENT_ID } from '../__generated__/common';
import { withScreenSpaceFlagsIntlProvider } from '../__generated__/intl-provider';
import type { Placement as ScreenSpaceFlagsPlacement } from '../contracts/Placement';

type State = {
	messageInstanceId: string;
	messageTemplateId: never;
	messageCategory: MessageCategory;
}[];

export const ScreenSpaceFlagsPlacementComponent = withScreenSpaceFlagsIntlProvider(
	createPlacementComponent<ScreenSpaceFlagsPlacement>(PLACEMENT_ID, ({ initialData }) => {
		const [localMessageState, setLocalMessageState] = useState<State | undefined>();

		useInitialData(initialData);

		const { onMessageDismiss, onMessageClicked } = useMessagePlacementHooks();

		const handleDismiss: DismissCallback = ({ messageInstanceId }) => {
			setLocalMessageState((current) =>
				current ? current.filter((e) => e.messageInstanceId !== messageInstanceId) : undefined,
			);
		};

		useEffect(() => onMessageDismiss(handleDismiss), [onMessageDismiss]);
		useEffect(() => onMessageClicked(handleDismiss), [onMessageClicked]);

		const activeScopes = useContextScope({ scopes });

		const messagesState = usePlacementApi<{
			messages: State;
		}>('/update-messages', {
			requestOptions: { method: 'POST', body: JSON.stringify(activeScopes) },
			throttleTime: 100,
			autoRefetch: {
				isEnabled: !!activeScopes.updateKey && typeof localMessageState === 'undefined',
				updateKey: activeScopes.updateKey ?? '',
			},
		});

		useEffect(() => {
			if (messagesState?.data?.messages?.length) {
				setLocalMessageState(messagesState.data.messages);
			}
		}, [messagesState.data]);

		return FeatureGates.checkGate('post-office-screen-space-flags-shadow') ? (
			<>
				{localMessageState
					?.slice(0, 1)
					.map(({ messageInstanceId, ...props }) => (
						<MessageRenderer
							{...props}
							key={messageInstanceId}
							id={messageInstanceId}
							messageInstanceId={messageInstanceId}
							onDismissed={() => setLocalMessageState([])}
						/>
					))}
			</>
		) : null;
	}),
);

export default ScreenSpaceFlagsPlacementComponent;
