type ServiceName = string;
type CustomURL = string;

type RenderInputType = Partial<{
	customURLs: Record<ServiceName, CustomURL> | undefined;
	userContext: string | undefined;
	userId: string | undefined;
	isLivePage: boolean | undefined;
	cookiesConsentToken: string | undefined;
	cookiesCurrentLocale: string | undefined;
	cookiesFallbackToken: string | undefined;
	perimeter: string;
}>;

let ssrRenderInputs: RenderInputType = {};

export function setSSRRenderInputs(inputs: RenderInputType) {
	ssrRenderInputs = inputs;
}

export function getSSRRenderInputs(): RenderInputType {
	return ssrRenderInputs;
}
