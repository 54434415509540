import { constructCompatibleRequestInit } from '@confluence/network/entry-points/constructCompatibleRequestInit';
import { SSRMeasures } from '@confluence/action-measures';
import { getMonitoringClient } from '@confluence/monitoring';
import { cfetch } from '@confluence/network';

import { SessionDataQueryRaw } from './SessionDataQueryRaw';
import type { SessionDataType } from './SessionDataTypes';
import type { SessionDataQuery as SessionDataQueryType } from './__types__/SessionDataQuery';
import type { FeatureFlagsQuery as FeatureFlagsQueryType } from './__types__/FeatureFlagsQuery';
import { FeatureFlagsQueryRaw } from './FeatureFlagsQueryRaw';

declare global {
	interface Window {
		USE_MOCKED_SESSION_DATA?: boolean;
		MOCK_SESSION_DATA_RESOLVE: (data: any) => void;
		__SESSION_DATA_PROMISE__: Promise<SessionDataType> | null;
		__SESSION_DATA__:
			| Promise<SessionDataQueryType & FeatureFlagsQueryType>
			| (SessionDataQueryType & FeatureFlagsQueryType)
			| Promise<null>
			| null;
	}
}

function fetchQueries(URI: string, queryName: string, queryRaw: string) {
	return SSRMeasures.run(`ssr-app/prepare/preloadQuery/fetch:${queryName}`, async () => {
		let result = null;
		try {
			const res = await cfetch(
				`${URI}?q=${queryName}`,
				constructCompatibleRequestInit({
					method: 'POST',
					credentials: 'include',
					referrerPolicy: 'same-origin',
					headers: {
						'X-APOLLO-OPERATION-NAME': queryName,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						operationName: queryName,
						query: queryRaw,
					}),
				}),
			);
			if (!res.ok) {
				throw new Error(`Cannot load ${queryName}: ${res.statusText}`);
			}
			result = (await res.json()).data;
		} catch (e) {
			getMonitoringClient().submitError(e, { attribution: 'FATAL' });
		}
		return result;
	});
}

/**
 * Apollo is too slow normalizing all the feature flags.
 * Getting session data using pure fetch
 */
export async function loadSessionData(): Promise<
	(SessionDataQueryType & FeatureFlagsQueryType) | null
> {
	if (process.env.NODE_ENV === 'testing' || window.USE_MOCKED_SESSION_DATA) {
		return new Promise((resolve) => {
			window['MOCK_SESSION_DATA_RESOLVE'] = resolve;
		});
	}
	// __SESSION_DATA__ is either actual session data
	// or the promise that is resolving the session data
	// In the case when page is rendered by SSR, the __SESSION_DATA__ will be populated with JSON data.
	if (window['__SESSION_DATA__']) {
		return window['__SESSION_DATA__'];
	}

	const URI = window['GLOBAL_APOLLO_CLIENT_URI'] || '/cgraphql';

	return (window['__SESSION_DATA__'] = Promise.all([
		fetchQueries(URI, 'SessionDataQuery', SessionDataQueryRaw),
		fetchQueries(URI, 'FeatureFlagsQuery', FeatureFlagsQueryRaw),
	])
		.then(([sessionData, featureFlags]) => {
			let result = null;
			if (Boolean(sessionData) && Boolean(featureFlags)) {
				result = {
					...sessionData,
					...featureFlags,
				};
			}
			return result;
		})
		.catch((e) => {
			getMonitoringClient().submitError(e, { attribution: 'FATAL' });
			return null;
		}));
}
