import isEqual from 'lodash/isEqual';
import memoize from 'memoize-one';
import type { FC } from 'react';
import React, { memo, useContext, useState } from 'react';
import type { ApolloError } from 'apollo-client';

import type { EventHandlers } from '@atlaskit/editor-common/ui';
import type { RendererAppearance, StickyHeaderProps } from '@atlaskit/renderer';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ContentRendererQueryVariablesType as VariablesType } from '@confluence/content-body';
import {
	getContentAppearanceForAtlaskit,
	getContentAppearancePublished,
} from '@confluence/content-appearance';
import { removeJquery } from '@confluence/dom-helpers';
import { Attribution, ErrorDisplay, withErrorBoundary } from '@confluence/error-boundary';
import { useInlineHighlights } from '@confluence/inline-highlights-query';
import {
	globalMacrosCSSRecorder,
	globalMacrosJSRecorder,
	superbatchCSSRecorder,
	superbatchJSRecorder,
} from '@confluence/ssr-utilities';
import { getMonitoringClient } from '@confluence/monitoring';
import { useSpaceId } from '@confluence/space-utils';
import { RendererExtensionContext } from '@confluence/content-renderer-extension-context';
import {
	scopeCSS,
	extractCSS,
} from '@confluence/content-renderer-legacy-macros/entry-points/scopeStyleMacro';

import type { ContentBodyType, ContentDataType, ContentNodeType } from './__types__';
import { RendererPerformanceMetrics } from './RendererPerformanceMetrics';
import { useConfluencePreviewsForceLoad } from './useConfluencePreviewsForceLoad';
import { PageContentRendererTiny, PageContentRendererFabric } from './renderers';
import { useGloballyDefinedMacroResources } from './useGloballyDefinedMacroResources';

export interface ContentRendererProps extends VariablesType {
	allowStickyHeaders?: boolean;
	appearance?: RendererAppearance;
	contentId?: string;
	data?: ContentDataType;
	error?: Error;
	eventHandlerOverrides?: Partial<EventHandlers>;
	hasInlineComments?: boolean;
	isPreviewMode?: boolean;
	isSpaceOverview?: boolean;
	isTemplateEditorRenderer?: boolean;
	onRendered?: () => void;
	queryHash?: string;
	spaceKey?: string | null;
	stickyHeaders?: StickyHeaderProps;
	isArchived?: boolean;
	allowAnnotations?: boolean;
	contentStatusError?: ApolloError;
	isEmbeddedPage?: boolean;
	isLivePageCacheOutdated?: boolean;
}

const renderPerformanceMetrics = memoize(
	(props) => () => <RendererPerformanceMetrics {...props} />,
	isEqual,
);

/**
 * Record superbatch to be included into HTML on the server
 * @param webresource -- superbatch CSS/JS links from monolith
 */
const recordSuperbatch = (webresource: any) => {
	const superbatchJs = webresource?.superbatch?.tags?.js || '';
	const superbatchCss = webresource?.superbatch?.tags?.css || '';

	if (!superbatchCSSRecorder.get()) {
		superbatchCSSRecorder.add(superbatchCss);
	}
	if (!superbatchJSRecorder.get()) {
		superbatchJSRecorder.addDeferTags(removeJquery(superbatchJs));
	}
};

/**
 * Record monolith-defined JS and CSS files for macros
 * @param webresource -- tags CSS/JS links from monolith
 */
const recordGlobalJsAndCSS = (webresource: any) => {
	const js = webresource?.tags?.js || '';
	const css = webresource?.tags?.css || '';
	if (!globalMacrosCSSRecorder.get()) {
		globalMacrosCSSRecorder.add(css);
	}
	if (!globalMacrosJSRecorder.get()) {
		globalMacrosJSRecorder.addDeferTags(js);
	}
};

export const includeSuperbatchForFabricMacro = (
	contentBody: any,
	macroRenderedOutput: any,
): void => {
	if (!process.env.REACT_SSR) {
		return;
	}

	const webresource = contentBody?.webresource;

	recordSuperbatch(webresource);

	/* to avoid type errors */
	if (macroRenderedOutput && macroRenderedOutput.length > 0) {
		window['__LEGACY_MACRO_RENDERED_OUTPUT__'] = macroRenderedOutput;
		window['__SSR_MACROS_INFO__'] = {
			successes: {},
			failures: {},
			successCount: 0,
			failureCount: 0,
		};
	}
};

/**
 * Component that renders content for Confluence Page
 * WARN: it must be renamed to PageContentRenderer to avoid confusion
 *
 * This uses the AtlasKit renderer component for rendering, and configures the
 * renderer to handle Confluence content (media, macros, etc.).
 *
 * The difference between this and `ContentRenderer` is that this utilizes the
 * dynamic content query, while `ContentRenderer` attempts to fetch ADF. This
 * an experiment and will be removed when finished.
 */
const PageContentRendererComponent: FC<ContentRendererProps> = memo(
	({
		appearance,
		contentId,
		data,
		error,
		eventHandlerOverrides = {},
		hasInlineComments = true,
		isPreviewMode,
		isSpaceOverview = false,
		isTemplateEditorRenderer,
		queryHash,
		spaceKey,
		isArchived,
		contentStatusError,
		allowAnnotations,
		isEmbeddedPage,
		isLivePageCacheOutdated,
		...rest
	}) => {
		// need to check space hasTheme to determine whether the page header is always fixed at the top
		const hasTheme = Boolean(data?.space?.theme?.themeKey);

		/* useInlineHighlights is custom hook that queries for markerRefs only if Inline Highlights performance is Enabled */
		const { markerRefs } = useInlineHighlights({
			pageId: contentId as string,
			shouldFireQuery: Boolean(process.env.REACT_SSR) && hasInlineComments,
		});
		const { getLegacyContentExtensions } = useContext(RendererExtensionContext);
		const [rootEl, setRootEl] = useState<HTMLDivElement | null>(null);
		const content: ContentNodeType = data?.content?.nodes?.[0] || null;
		const contentBody: ContentBodyType = content?.body?.dynamic || null;

		const { createAnalyticsEvent } = useAnalyticsEvents();
		const spaceId = useSpaceId();

		useGloballyDefinedMacroResources({
			contentBody,
		});
		useConfluencePreviewsForceLoad(contentId);

		if (error) {
			return <ErrorDisplay error={error} />;
		}

		// Page content should not be rendered when there is no data, and also when
		// extension providers/handlers are not yet ready which can lead to content
		// flickering and thrashing. but we can't exit earlier as hooks can't be conditional
		if (!contentId || !data) {
			return null;
		}

		// With https://app.launchdarkly.com/confluence/staging/features/confluence.macro.execution.adf.extension.macroNames/targeting
		// the output is not put into macroRenderedOutput, and is included on top level.
		// We use the resourceRecorder only in SSR environment.
		const revision = content?.version?.confRev || '';
		const isFabric = contentBody?.representation === 'atlas_doc_format';
		const webresource = contentBody?.webresource;
		const lastModifiedDate = content?.metadata?.lastModifiedDate;

		if (process.env.REACT_SSR && isFabric && (webresource?.tags?.js || webresource?.tags?.css)) {
			recordSuperbatch(webresource);
			recordGlobalJsAndCSS(webresource);
		}

		let contentAppearance = appearance;
		if (!contentAppearance && content) {
			contentAppearance = getContentAppearanceForAtlaskit(
				getContentAppearancePublished(content).appearance,
			);
		}

		window.performance.mark('CFP-63.next.content-renderer.rendered');

		if (isFabric) {
			return (
				<PageContentRendererFabric
					content={content}
					contentAppearance={contentAppearance}
					contentBody={contentBody}
					contentId={contentId}
					eventHandlerOverrides={eventHandlerOverrides}
					hasInlineComments={hasInlineComments}
					isPreviewMode={isPreviewMode}
					isSpaceOverview={isSpaceOverview}
					isTemplateEditorRenderer={isTemplateEditorRenderer}
					markerRefs={markerRefs}
					queryHash={queryHash}
					renderPerformanceMetrics={renderPerformanceMetrics}
					revision={revision}
					rootEl={rootEl}
					setRootEl={setRootEl}
					spaceKey={spaceKey}
					spaceId={spaceId}
					isArchived={isArchived}
					contentStatusError={contentStatusError}
					isContentHeaderFixedAtTop={!hasTheme && !isEmbeddedPage}
					allowAnnotations={allowAnnotations}
					isLivePageCacheOutdated={isLivePageCacheOutdated}
					{...rest}
				/>
			);
		}

		// Scope Style Macros CSS for Tiny Page
		try {
			const { CSSForAnalytics, contentWithScopedCSS } = scopeCSS(contentBody?.value || '');

			// The component can load multiple times, contentWithScopedCSS with the same contentBody.value to prevent sending multiple events
			if (
				CSSForAnalytics &&
				!process.env.REACT_SSR &&
				contentWithScopedCSS !== contentBody?.value
			) {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'scoped',
						actionSubject: 'legacyStyleMacro',
						source: 'PageContentRenderer',
						attributes: {
							CSSForAnalytics,
						},
					},
				}).fire();
			}

			if (contentBody) {
				contentBody.value = contentWithScopedCSS;
			}
		} catch (error) {
			const extractedCSS = extractCSS(contentBody?.value || '');
			if (process.env.REACT_SSR) {
				getMonitoringClient().submitError(
					{ error, extractedCSS },
					{ attribution: Attribution.BACKBONE },
				);
			} else {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'error',
						actionSubject: 'legacyStyleMacro',
						source: 'PageContentRenderer',
						attributes: {
							error,
							extractedCSS,
						},
					},
				}).fire();
			}
		}

		return (
			<PageContentRendererTiny
				contentAppearance={contentAppearance}
				contentBody={contentBody}
				contentId={contentId}
				getLegacyContentExtensions={getLegacyContentExtensions}
				markerRefs={markerRefs}
				queryHash={queryHash}
				renderPerformanceMetrics={renderPerformanceMetrics}
				revision={revision}
				setRootEl={setRootEl}
				lastModifiedDate={lastModifiedDate}
			/>
		);
	},
);

const PageContentRenderer = withErrorBoundary({
	attribution: Attribution.BACKBONE,
})(PageContentRendererComponent);
PageContentRenderer.displayName = 'PageContentRenderer';

export { PageContentRenderer };
