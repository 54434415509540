import { createContext, useContext } from 'react';

import type { PostOfficeContextValue } from './types';

export const defaultPostOfficeContextValue: PostOfficeContextValue = {
	product: undefined,
	subproduct: undefined,
	locale: undefined,
	isSiteAdmin: undefined,
	tenantId: undefined,
	workspaceAri: 'no-workspace-ari-available',
	workspaceId: undefined,
	orgId: undefined,
	productEdition: undefined,
};

export const PostOfficeContext = createContext<PostOfficeContextValue>(
	defaultPostOfficeContextValue,
);

export const InternalPostOfficeContextProvider = PostOfficeContext.Provider;

/**
 * @deprecated Since version 0.1 - will be removed in v1.0.
 * Use PostOfficeProvider instead.
 *
 * `PostOfficeContextProvider` is a React context provider used to pass down
 * `PostOfficeContextValue` to every placement
 *
 * @context
 * @typedef {Object} PostOfficeContextValue
 * @property {string | undefined} [orgId] - The unique identifier for the organization. Can be `undefined`.
 * @property {string | undefined} product - The name of the product. Can be `undefined`.
 * @property {string | undefined} [subproduct] - The name of the subproduct. Can be `undefined`.
 * @property {string | undefined} [tenantId] - The unique tenant identifier. Can be `undefined`.
 * @property {boolean | undefined} [isSiteAdmin] - Flag indicating if the current user is a site administrator. Can be `undefined`.
 * @property {string | undefined} [productEdition] - The name of the product edition. Can be `undefined`.
 * @property {ARI | 'no-workspace-ari-available'} [workspaceAri] - The identifier for the workspace. Can be `no-workspace-ari-available`.
 * @property {string | undefined} [workspaceId] - The identifier for the workspace. Can be `undefined`.
 * @property {string | undefined} locale - The current locale as a string. Can be `undefined`.
 */
export const PostOfficeContextProvider = InternalPostOfficeContextProvider;

export const usePostOfficeContext = (): PostOfficeContextValue => useContext(PostOfficeContext);
