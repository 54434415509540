import type { GasPurePayload } from '@atlaskit/analytics-gas-types';
import { addBrowserMetricEvent } from '@atlassian/react-ufo/interaction-metrics';

import type { BaseMetricData } from '../metric/base-metric';
import type { BasePageLoadMetricData } from '../metric/base-page-load-metric';
import { type BrowserMetricsConfig, PageVisibleFields } from '../types';

import { eventDataProcessor } from './event-data-processor';
import {
	makeShareableGlobalConfig,
	type ShareableGlobalConfigPerEventType,
} from './shareable-global-config';
import type { HistogramConfig } from './types';

interface Submitter {
	config: {
		gasv3?: Promise<{
			sendOperationalEvent: (payload: GasPurePayload) => void;
		}>;
		configChunk: Promise<ShareableGlobalConfigPerEventType> | null;
		histogram: HistogramConfig;
		useVisibleState: boolean;
		enableReactUFOTracker?: boolean;
	};
	configure(config: BrowserMetricsConfig): void;
	queue(data: BaseMetricData | BasePageLoadMetricData): void;
	debug: boolean;
	setDebug: (debug: boolean) => void;
}

export const submitter: Submitter = {
	debug: false,
	config: {
		gasv3: undefined,
		configChunk: null,
		useVisibleState: false,
		histogram: {},
		enableReactUFOTracker: false,
	},
	configure(config: BrowserMetricsConfig) {
		this.config.gasv3 = config.endpoints.eventPipelineClient;
		this.config.enableReactUFOTracker = config.enableReactUFOTracker;
		this.config.configChunk = makeShareableGlobalConfig(config);
		if (config.events.all && config.events.all.sfxEvents) {
			this.config.useVisibleState =
				config.events.all.sfxEvents.activeTab === PageVisibleFields.pageVisibleState;
		}
	},
	setDebug(debug: boolean) {
		this.debug = debug;
	},
	async queue(data: BaseMetricData | BasePageLoadMetricData) {
		if (this.config.enableReactUFOTracker) {
			addBrowserMetricEvent(data);
		}
		if (!this.config.configChunk) {
			// eslint-disable-next-line no-console
			this.debug && console.warn('Submitter is not configured');
			return null;
		}
		const chunk = await this.config.configChunk;
		const payload: { [key: string]: unknown } | null = await eventDataProcessor(
			chunk[data.config.type],
			data,
		);
		if (payload === null) {
			return;
		}

		if (this.config.gasv3 !== undefined) {
			const client = await this.config.gasv3;
			const attributes = {
				task: payload['event:key'],
				properties: payload,
			};
			client &&
				client.sendOperationalEvent({
					actionSubject: 'performance',
					attributes,
					source: 'performance',
					action: 'measured',
				});
		}
	},
};
