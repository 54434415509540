// used for page-load and page-segment-load events
export const FF_ALLOWLIST_FOR_PERF_METRICS = [
	'confluence.frontend.delay.page.comments.render',
	'confluence.frontend.presentation.mode',
	'confluence.ssr.page-statuses',
	'confluence.frontend.fabric.editor.macro.adf.frontend.render',
	'confluence.frontend.fabric.editor.preload.timeout',
	'confluence.frontend.custom-sites.page-header-and-title',
	'confluence.frontend.custom-sites.cards-extension',
	'confluence.ssr.preload.query.timeout',
	'confluence.ssr.render.above.threshold',
	'confluence.ssr.delay.hydration.on.hover',
	'confluence.ssr.remove.preload.space.overview.query',
	'confluence.ssr.use-react18',
	'confluence.frontend.legacy.bridge.query.migration_0f25n',
	'platform.analytics-next-use-modern-context_fqgbx',
	'confluence.frontend.load-document-draft-from-graphql',
	'confluence_ssr_enable_tesseract_stream_endpoint',
	'confluence.frontend.editor.ssr',
	'confluence_frontend_editor_preload_mentions',
	'confluence_frontend_livepages_fastloading',
	'confluence.frontend.use-multiple-macros-query',
	'ssr_forge_apps_in_side_navigation',
];

// In order to reduce BM event payload size, include a FF in the BM payload only if it's in the allow list.
// The following FFs will be included in all types of BM event.
export const ALLOWLIST_FFS_CUSTOM_EVENTS = [
	'confluence.frontend.pollinator.synthetic.check.tenants',
	'confluence.ssr.delay.hydration.on.hover',
];
